import React from "react";

import styles from "./Pagination.module.scss";

type Props = {
  itemsPerPage: number;
  totalItems: number;
  paginate: (pageNumber: number) => void;
  currentPage: number;
};

const Pagination = ({
  itemsPerPage,
  totalItems,
  paginate,
  currentPage,
}: Props) => {
  const pageNumbers = [];
  const pageNumbersToShow = 5;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const getVisiblePageNumbers = (): number[] => {
    // Determine the start and end pages to display
    let startPage = Math.max(
      currentPage - Math.floor(pageNumbersToShow / 2),
      1
    );
    let endPage = startPage + pageNumbersToShow - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(endPage - pageNumbersToShow + 1, 1);
    }

    // Return a subset of page numbers
    return Array.from(
      { length: endPage - startPage + 1 },
      (_, index) => startPage + index
    );
  };

  const visiblePageNumbers = getVisiblePageNumbers();

  return (
    <nav className={styles.Pagination}>
      <button
        onClick={() => currentPage > 1 && paginate(currentPage - 1)}
        className={`px-4 py-2 mx-1 text-white rounded-md ${
          currentPage > 1 ? "bg-primary-1 hover:bg-primary-2" : "bg-gray-300"
        }`}
        disabled={currentPage === 1}
      >
        અગાઉના
      </button>
      <ul className="flex lg:flex-wrap">
        {/* {currentPage > 1 && (
          <li className="mx-1">
            <button
              onClick={() => paginate(currentPage - 1)}
              className="px-4 py-2 rounded-md text-white bg-primary-1 hover:bg-primary-2 transition-colors duration-200"
            >
              &laquo;
            </button>
          </li>
        )} */}

        {visiblePageNumbers.map((number) => (
          <li key={number} className="mx-1">
            <button
              onClick={() => paginate(number)}
              className={`px-4 py-2 rounded-md text-white transition-colors duration-200 ${
                currentPage === number
                  ? "bg-primary-2"
                  : "bg-primary-1 hover:bg-primary-2"
              }`}
            >
              {number}
            </button>
          </li>
        ))}

        {/* {currentPage < totalPages && (
          <li className="mx-1">
            <button
              onClick={() => paginate(currentPage + 1)}
              className="px-4 py-2 rounded-md text-white bg-primary-1 hover:bg-primary-2 transition-colors duration-200"
            >
              &raquo;
            </button>
          </li>
        )} */}
      </ul>
      <button
        onClick={() =>
          currentPage < pageNumbers.length && paginate(currentPage + 1)
        }
        className={`px-4 py-2 mx-1 text-white rounded-md ${
          currentPage < pageNumbers.length
            ? "bg-primary-1 hover:bg-primary-2"
            : "bg-gray-300"
        }`}
        disabled={currentPage === pageNumbers.length}
      >
        આગળ
      </button>
    </nav>
  );
};

export default Pagination;
