import React, { useEffect, useState } from "react";

import styles from "./EditForm.module.scss";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { GiCancel } from "react-icons/gi";

import { SubmitHandler, useForm } from "react-hook-form";
import { IFormInput } from "../../types/salaries";
import { FaCalendarAlt } from "react-icons/fa";
import { useParams } from "react-router-dom";
import {
  getEditSalary,
  setEditSalary,
  toggleCreateModal,
} from "../../features/salaries/salariesSlice";
import moment from "moment";

type Props = {
  handleSaveSalaries: (data: IFormInput) => void;
};

const EditForm = ({ handleSaveSalaries }: Props) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const editSalary = useAppSelector(getEditSalary);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [subAmount, setSubAmount] = useState<number>(0);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [totalWithdrawal, setTotalWithdrawal] = useState<number>(0);
  const [lastTotalWithdrawal, setLastTotalWithdrawal] = useState<number>(0);
  const [lastTotalAmount, setLastTotalAmount] = useState<number>(0);

  const {
    handleSubmit,
    register,
    reset,
    getValues,
    formState: { errors },
  } = useForm<IFormInput>();

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    if (id) {
      setIsLoading(true);
      await handleSaveSalaries({
        ...data,
        status: 0,
        id: editSalary?.id,
      });
      setIsLoading(false);
      handleCloseModal();
    }
  };

  const handleCloseModal = () => {
    reset();
    dispatch(toggleCreateModal(0));
    dispatch(setEditSalary(null));
  };

  useEffect(() => {
    if (editSalary && editSalary.id) {
      setSubAmount(editSalary.amount || 0);
      setTotalAmount(editSalary.total_amount || 0);
      setTotalWithdrawal(editSalary.withdrawal_amount || 0);
      setLastTotalWithdrawal(
        (editSalary.withdrawal_amount || 0) -
          (editSalary.withdrawal_deposit || 0)
      );
      setLastTotalAmount(editSalary.total_amount || 0);

      reset({
        fromDate: editSalary.from_date,
        toDate: editSalary.to_date,
        roundoff_amount: editSalary.roundoff_amount,
        withdrawal_deposit: editSalary.withdrawal_deposit,
      });
    }
  }, [editSalary]);

  return (
    <div className={styles.EditForm}>
      <div className="relative h-[100vh] w-[100vw] overflow-y-scroll lg:overflow-auto top-0 lg:top-10 lg:mx-auto p-5 border lg:w-[80vw] lg:h-fit lg:min-h-[calc(100vh-80px)] shadow-lg lg:rounded-md bg-white">
        <div className="mt-3">
          <h3 className="text-2xl mt-10 lg:mt-0 text-center lg:text-left font-bold leading-6 text-primary-1">
            હિસાબ ની માહિતી
          </h3>

          <GiCancel
            onClick={handleCloseModal}
            className="absolute cursor-pointer z-10 shadow bg-white top-3 right-5 text-3xl lg:text-xl text-primary-1 border-2 border-primary-1 p-1 lg:p-0 rounded-full"
          />
          <div className="mt-2 py-3 lg:py-2">
            <form onSubmit={handleSubmit(onSubmit)} className="p-4 pt-1 pb-0">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6">
                <div>
                  <label className="text-primary-1 font-bold">તારીખ થી :</label>
                  <div className="flex items-center bg-gradient-to-r from-primary-1 to-primary-2 rounded pr-[2px] h-[44px]">
                    <FaCalendarAlt className="text-white mx-2" />
                    <input
                      className="bg-gray-300 text-primary-1 placeholder:text-primary-2 font-semibold text-xl focus:outline-none p-2 rounded flex-grow h-[40px]"
                      type="text"
                      value={moment(editSalary?.from_date).format("DD-MM-YYYY")}
                      placeholder="From Date"
                      readOnly
                    />
                  </div>
                </div>

                <div>
                  <label className="text-primary-1 font-bold">
                    તારીખ સુધી :
                  </label>
                  <div className="flex items-center bg-gradient-to-r from-primary-1 to-primary-2 rounded pr-[2px] h-[44px]">
                    <FaCalendarAlt className="text-white mx-2" />
                    <input
                      className="bg-gray-300 text-primary-1  placeholder:text-primary-2 font-semibold text-xl focus:outline-none p-2 rounded flex-grow h-[40px]"
                      type="text"
                      value={moment(editSalary?.to_date).format("DD-MM-YYYY")}
                      placeholder="To Date"
                      readOnly
                    />
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
                <div className="mt-1">
                  <label className="text-primary-1 font-bold">
                    હિસાબ કલાકો * Rs. :
                  </label>
                  <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-11 w-full relative rounded">
                    <input
                      value={`${editSalary?.note?.hours || 0} * ${
                        editSalary?.note?.amount || 0
                      }`}
                      className="text-primary-1 bg-gray-300 focus:outline-none px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                      type="text"
                      readOnly
                    />
                  </div>
                </div>

                <div className="mt-1">
                  <label className="text-primary-1 font-bold">
                    હિસાબ અમાઉન્ટ :
                  </label>
                  <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-11 w-full relative rounded">
                    <input
                      value={subAmount || 0}
                      className="text-primary-1 bg-gray-300 focus:outline-none px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                      type="text"
                      readOnly
                    />
                  </div>
                </div>

                <div className="mt-1">
                  <label className="text-primary-1 font-bold">
                    રાઉન્ડ ઑફ :
                  </label>
                  <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-11 w-full relative rounded">
                    <input
                      className="text-primary-1 focus:outline-none px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                      type="number"
                      pattern="-?[0-9]*"
                      step={"any"}
                      placeholder="રાઉન્ડ ઑફ દાખલ કરો"
                      {...register("roundoff_amount", { required: false })}
                      onFocus={(e) =>
                        e.target.addEventListener(
                          "wheel",
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                      onKeyUp={(e: any) => {
                        const roundoff_amount = e.target.value || 0;
                        const { withdrawal_deposit } = getValues();

                        const total =
                          parseFloat(`${subAmount || 0}`) +
                          parseFloat(`${roundoff_amount || 0}`);
                        setTotalAmount(total);

                        const total1 =
                          parseFloat(`${totalWithdrawal || 0}`) -
                          parseFloat(`${withdrawal_deposit || 0}`);
                        setLastTotalWithdrawal(total1);

                        const total2 =
                          parseFloat(`${total || 0}`) -
                          parseFloat(`${withdrawal_deposit || 0}`);
                        setLastTotalAmount(total2);

                        return true;
                      }}
                    />
                  </div>
                  {errors.roundoff_amount &&
                    errors.roundoff_amount.type === "required" && (
                      <span className="text-red-500">રાઉન્ડ ઑફ દાખલ કરો</span>
                    )}
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
                <div className="mt-1">
                  <label className="text-primary-1 font-bold">
                    હિસાબ ટોટલ અમાઉન્ટ :
                  </label>
                  <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-11 w-full relative rounded">
                    <input
                      value={`${(totalAmount || 0)?.toFixed(2)}`}
                      className="text-primary-1 bg-gray-300 focus:outline-none px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                      type="text"
                      readOnly
                    />
                  </div>
                </div>

                <div className="mt-1">
                  <label className="text-primary-1 font-bold">
                    ટોટલ ઉપાડ :
                  </label>
                  <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-11 w-full relative rounded">
                    <input
                      value={`${(totalWithdrawal || 0)?.toFixed(2)}`}
                      className="text-primary-1 bg-gray-300 focus:outline-none px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                      type="text"
                      readOnly
                    />
                  </div>
                </div>

                <div className="mt-1">
                  <label className="text-primary-1 font-bold">
                    કપાત ઉપાડ :
                  </label>
                  <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-11 w-full relative rounded">
                    <input
                      className="text-primary-1 focus:outline-none px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                      type="number"
                      pattern="-?[0-9]*"
                      step={"any"}
                      placeholder="કપાત ઉપાડ દાખલ કરો"
                      {...register("withdrawal_deposit", {
                        required: false,
                      })}
                      onFocus={(e) =>
                        e.target.addEventListener(
                          "wheel",
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                      onKeyUp={(e: any) => {
                        const withdrawal_deposit = e.target.value || 0;
                        const { roundoff_amount } = getValues();

                        const total =
                          parseFloat(`${subAmount || 0}`) +
                          parseFloat(`${roundoff_amount || 0}`);
                        setTotalAmount(total);

                        const total1 =
                          parseFloat(`${totalWithdrawal || 0}`) -
                          parseFloat(`${withdrawal_deposit || 0}`);
                        setLastTotalWithdrawal(total1);

                        const total2 =
                          parseFloat(`${total || 0}`) -
                          parseFloat(`${withdrawal_deposit || 0}`);
                        setLastTotalAmount(total2);

                        return true;
                      }}
                    />
                  </div>
                  {errors.withdrawal_deposit &&
                    errors.withdrawal_deposit.type === "required" && (
                      <span className="text-red-500">કપાત ઉપાડ દાખલ કરો</span>
                    )}
                </div>
              </div>

              <h3 className="text-2xl mt-6 text-left font-bold leading-8 text-primary-1">
                બાકી ઉપાડ અમાઉન્ટ - Rs.{" "}
                {lastTotalWithdrawal ? lastTotalWithdrawal?.toFixed(2) : "0.00"}
              </h3>

              <h3 className="text-2xl mt-6 text-left font-bold leading-8 text-primary-1">
                પગાર અમાઉન્ટ - Rs.{" "}
                {lastTotalAmount ? lastTotalAmount?.toFixed(2) : "0.00"}
              </h3>

              <div className="mt-4 text-center">
                <button
                  className="mx-auto shadow disabled:opacity-50 rounded-3xl text-3xl lg:text-xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? "રાહ જુઓ..." : "આગળ વધો"}
                </button>
              </div>

              <div className="mt-4 text-center">
                <button
                  className="mx-auto shadow disabled:opacity-50 rounded-3xl text-3xl lg:text-xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
                  type="button"
                  onClick={handleCloseModal}
                  disabled={isLoading}
                >
                  બંધ કરો
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditForm;
