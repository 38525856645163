import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

import styles from "./PaymentForm.module.scss";
import {
  getEditPayment,
  setEditPayment,
  toggleCreateModal,
} from "../../features/payments/paymentsSlice";
import { IFormInputPayment } from "../../types/payments";
import { useParams } from "react-router-dom";

type Props = {
  handleSavePayment: (data: IFormInputPayment) => void;
};

const PaymentForm = ({ handleSavePayment }: Props) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const editPayment = useAppSelector(getEditPayment);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<IFormInputPayment>({
    defaultValues: { amount: null, note: "" },
  });

  const handleOnSubmit: SubmitHandler<IFormInputPayment> = async (
    data: any
  ) => {
    setIsLoading(true);
    if (editPayment && editPayment.id) {
      await handleSavePayment({ ...data, id: editPayment.id, type: 1 });
    } else {
      await handleSavePayment({ ...data, type: 1, party_id: id });
    }
    handleCloseFormModal();
    setIsLoading(false);
  };

  const handleCloseFormModal = () => {
    reset();
    dispatch(toggleCreateModal(0));
    dispatch(setEditPayment(null));
  };

  useEffect(() => {
    if (editPayment && editPayment.id) {
      reset({ amount: editPayment.amount, note: editPayment.note });
    } else {
      reset({ amount: null, note: "" });
    }
  }, [editPayment]);

  return (
    <div className={styles.PaymentForm}>
      <div className="relative h-[100vh] w-[100vw] overflow-y-scroll lg:overflow-auto top-0 lg:top-10 lg:mx-auto p-5 border lg:w-96 lg:h-fit shadow-lg lg:rounded-md bg-white">
        <div className="mt-3">
          <h3 className="text-2xl text-center font-bold leading-6 text-primary-1">
            આવેલ પેમેન્ટ ની માહિતી
          </h3>
          <div className="mt-2 py-3">
            <form onSubmit={handleSubmit(handleOnSubmit)}>
              <div className="grid gap-4">
                <div className="mt-6">
                  <label className="text-primary-1 font-bold">
                    પેમેન્ટ નું અમાઉન્ટ :
                  </label>
                  <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-11 w-full relative rounded">
                    <input
                      className="text-primary-1 px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                      type="number"
                      pattern="-?[0-9]*"
                      step={"any"}
                      placeholder="પેમેન્ટ નું અમાઉન્ટ દાખલ કરો"
                      {...register("amount", {
                        required: true,
                        min: 0,
                      })}
                      onFocus={(e) =>
                        e.target.addEventListener(
                          "wheel",
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                    />
                  </div>
                  {errors.amount && (
                    <span className="text-red-500">
                      પેમેન્ટ નું અમાઉન્ટ દાખલ કરો
                    </span>
                  )}
                </div>

                <div className="mt-4">
                  <label className="text-primary-1 font-bold">
                    પેમેન્ટ ની નોંધ :
                  </label>
                  <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-11 w-full relative rounded">
                    <input
                      className="text-primary-1 px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                      type="text"
                      placeholder="પેમેન્ટ ની નોંધ દાખલ કરો"
                      {...register("note", {
                        //   required: true,
                      })}
                    />
                  </div>
                  {errors.note && (
                    <span className="text-red-500">
                      પેમેન્ટ ની નોંધ દાખલ કરો
                    </span>
                  )}
                </div>

                <div className="mt-3 text-center">
                  <button
                    className="mx-auto shadow disabled:opacity-50 rounded-3xl text-3xl lg:text-xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? "રાહ જુઓ..." : "આગળ વધો"}
                  </button>
                </div>

                <div className="mt-1 text-center">
                  <button
                    className="mx-auto shadow disabled:opacity-50 rounded-3xl text-3xl lg:text-xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
                    type="button"
                    onClick={handleCloseFormModal}
                    disabled={isLoading}
                  >
                    બંધ કરો
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentForm;
