import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getEditProcess,
  getIsProcessModalOpen,
  toggleProcessModal,
} from "../../features/processes/processesSlice";

import styles from "./ProcessFormModal.module.scss";
import { IFormInput } from "../../types/process";

type Props = {
  handleSaveProcess: (data: IFormInput) => void;
};

const ProcessFormModal = ({ handleSaveProcess }: Props) => {
  const dispatch = useAppDispatch();
  const editProcess = useAppSelector(getEditProcess);
  const isProcessModalOpen = useAppSelector(getIsProcessModalOpen);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<IFormInput>({ defaultValues: { name: "", description: "" } });

  const handleOnSubmit: SubmitHandler<IFormInput> = async (data: any) => {
    setIsLoading(true);
    await handleSaveProcess(data);
    handleCloseFormModal();
    setIsLoading(false);
  };

  const handleCloseFormModal = () => {
    reset();
    dispatch(toggleProcessModal(false));
  };

  useEffect(() => {
    if (editProcess && editProcess.id) {
      reset({ name: editProcess.name, description: editProcess.description });
    } else {
      reset({ name: "", description: "" });
    }
  }, [editProcess, isProcessModalOpen]);

  return (
    <>
      {isProcessModalOpen === true && (
        <div className={styles.ProcessFormModal}>
          <div className="relative h-[100vh] w-[100vw] overflow-y-scroll lg:overflow-auto top-0 lg:top-10 lg:mx-auto p-5 border lg:w-96 lg:h-fit shadow-lg lg:rounded-md bg-white">
            <div className="mt-3">
              <h3 className="text-2xl text-center font-bold leading-6 text-primary-1">
                પ્રોસેસ ની માહિતી
              </h3>
              <div className="mt-2 py-3">
                <form onSubmit={handleSubmit(handleOnSubmit)}>
                  <div className="grid gap-4">
                    <div className="mt-6">
                      <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-14 w-full relative rounded">
                        <input
                          className="text-primary-1 focus:outline-none px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                          type="text"
                          placeholder="પ્રોસેસનુ નામ દાખલ કરો"
                          {...register("name", { required: true })}
                        />
                      </div>
                      {errors.name && errors.name.type === "required" && (
                        <span className="text-red-500">
                          પ્રોસેસનુ નામ દાખલ કરો
                        </span>
                      )}
                    </div>

                    <div className="mt-3">
                      <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-28 w-full relative rounded">
                        <textarea
                          rows={5}
                          className="text-primary-1 focus:outline-none px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                          placeholder="પ્રોસેસનુ ડિસ્ક્રિપ્શન દાખલ કરો"
                          {...register("description", { required: false })}
                        />
                      </div>
                      {errors.description &&
                        errors.description.type === "required" && (
                          <span className="text-red-500">
                            પ્રોસેસનુ ડિસ્ક્રિપ્શન દાખલ કરો
                          </span>
                        )}
                    </div>

                    <div className="mt-3 text-center">
                      <button
                        className="mx-auto shadow disabled:opacity-50 rounded-3xl text-3xl lg:text-xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
                        type="submit"
                        disabled={isLoading}
                      >
                        {isLoading ? "રાહ જુઓ..." : "આગળ વધો"}
                      </button>
                    </div>

                    <div className="mt-1 text-center">
                      <button
                        className="mx-auto shadow disabled:opacity-50 rounded-3xl text-3xl lg:text-xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
                        type="button"
                        onClick={handleCloseFormModal}
                        disabled={isLoading}
                      >
                        બંધ કરો
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProcessFormModal;
