import React, { useEffect, useState } from "react";
import Layout from "../../components/layout";
import { FaArrowLeft, FaPlus, FaSearch, FaCalendarAlt } from "react-icons/fa";

import styles from "./PartiesPage.module.scss";
import { useNavigate } from "react-router-dom";
import { route } from "../../static/route";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  fetchPartiesListData,
  getEditParty,
  getParties,
  getTotalParties,
  getTotalPendingAmount,
  getTotalReceivedAmount,
  setEditParty,
  togglePartyModal,
} from "../../features/parties/partiesSlice";
import PartyList from "../../components/parties/PartyList";
import Pagination from "../../components/pagination";
import PartyFormModal from "../../components/parties/PartyFormModal";
import { IFormInput, ItemFormItem } from "../../types/parties";
import { toast } from "react-toastify";
import { toastStyles } from "../../static/styles";
import { DELETE, POST, PUT } from "../../services/axios.service";
import { useForm } from "react-hook-form";
import {
  getIsPartiesItemModalOpen,
  togglePartiesItemModal,
} from "../../features/parties/partiesItemSlice";
import ItemFormModal from "../../components/parties/ItemFormModal";

const PartiesPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const parties = useAppSelector(getParties);
  const totalParties = useAppSelector(getTotalParties);
  const editParty = useAppSelector(getEditParty);
  const isPartiesItemModalOpen = useAppSelector(getIsPartiesItemModalOpen);
  const totalPendingAmount = useAppSelector(getTotalPendingAmount);
  const totalReceivedAmount = useAppSelector(getTotalReceivedAmount);

  interface FilterFormInput {
    name: string;
    fromDate: string;
    toDate: string;
  }

  const { handleSubmit, register, reset, getValues } =
    useForm<FilterFormInput>();

  const [page, setPage] = useState<number>(1);
  const [pageSize] = useState<number>(50);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState(false);

  const hanldeGoToHome = () => {
    navigate(route.dashboard);
  };

  const paginate = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const handleShowPartyForm = async () => {
    await dispatch(setEditParty(null));
    dispatch(togglePartyModal(true));
  };

  const handleSaveParty = async (data: IFormInput) => {
    try {
      if (editParty && editParty.id) {
        await PUT(`/v1/admin/parties/${editParty.id}`, {
          firm_name: data.firm_name,
          name: data.name,
          phone: data.phone,
          wp_phone: data.wp_phone,
          address: data.address,
        })
          .then(async (response: any) => {
            if (response.data.status === "success") {
              await dispatch(setEditParty(null));

              if (page === 1) {
                fetchPartiesList(page, pageSize);
              } else {
                setPage(1);
              }

              toast.success(response.data.message, {
                ...(toastStyles as any),
              });
            } else {
              toast.error(response.data.message, {
                ...(toastStyles as any),
              });
            }
          })
          .catch((error: any) => {
            toast.error(error.data.message, {
              ...(toastStyles as any),
            });
          });
      } else {
        await POST("/v1/admin/parties", {
          firm_name: data.firm_name,
          name: data.name,
          phone: data.phone,
          wp_phone: data.wp_phone,
          address: data.address,
        })
          .then(async (response: any) => {
            if (response.data.status === "success") {
              if (page === 1) {
                fetchPartiesList(page, pageSize);
              } else {
                setPage(1);
              }

              toast.success(response.data.message, {
                ...(toastStyles as any),
              });
            } else {
              toast.error(response.data.message, {
                ...(toastStyles as any),
              });
            }
          })
          .catch((error: any) => {
            toast.error(error.data.message, {
              ...(toastStyles as any),
            });
          });
      }
    } catch (error) {
      toast.error("કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.", {
        ...(toastStyles as any),
      });
    }
  };

  const handleSavePartyItem = async (id: number, data: ItemFormItem[]) => {
    try {
      await PUT(`/v1/admin/parties/${id}/items`, {
        items: data || [],
      })
        .then(async (response: any) => {
          if (response.data.status === "success") {
            await dispatch(togglePartiesItemModal(false));
            fetchPartiesList(page, pageSize);

            toast.success(response.data.message, {
              ...(toastStyles as any),
            });
          } else {
            toast.error(response.data.message, {
              ...(toastStyles as any),
            });
          }
        })
        .catch((error: any) => {
          toast.error(error.data.message, {
            ...(toastStyles as any),
          });
        });
    } catch (error) {
      toast.error("કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.", {
        ...(toastStyles as any),
      });
    }
  };

  const handleDeleteParty = async (id: number) => {
    try {
      await DELETE(`/v1/admin/parties/${id}`)
        .then(async (response: any) => {
          if (response.data.status === "success") {
            const pageNumber = parties.length > 1 ? page : page - 1;
            if (page === pageNumber) {
              fetchPartiesList(page, pageSize);
            } else {
              setPage(pageNumber);
            }

            toast.success(response.data.message, {
              ...(toastStyles as any),
            });
          } else {
            toast.error(response.data.message, {
              ...(toastStyles as any),
            });
          }
        })
        .catch((error: any) => {
          toast.error(error.data.message, {
            ...(toastStyles as any),
          });
        });
    } catch (error) {
      toast.error("કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.", {
        ...(toastStyles as any),
      });
    }
  };

  const handleOnFilterSubmit = async () => {
    fetchPartiesList(page, pageSize);
  };

  const handleClearFilter = () => {
    reset();
    fetchPartiesList(page, pageSize);
  };

  const fetchPartiesList = async (page: number, size: number) => {
    const data = getValues();

    setIsLoading(true);

    dispatch(
      fetchPartiesListData({
        page: page,
        size: size,
        search: data?.name || null,
        fromDate: data?.fromDate || null,
        toDate: data?.toDate || null,
      })
    ).then(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetchPartiesList(page, pageSize);
  }, [page]);

  return (
    <Layout>
      <div className={styles.PartiesPage}>
        <div className="logoSection">
          <h1
            onClick={hanldeGoToHome}
            className="text-5xl font-bold text-white lg:text-3xl cursor-pointer lg:w-fit"
          >
            કનૈયા પ્લેટિંગ
          </h1>

          <hr className="w-[180px] border-white border-[4px] rounded-[4px] mx-auto mt-4 lg:mx-0 lg:w-24 lg:border-[2px] lg:mt-2" />
        </div>

        <div className="pageTitleSection">
          <FaArrowLeft
            onClick={hanldeGoToHome}
            className="lg:hidden absolute top-5 left-5 text-3xl text-white border-2 border-white p-1 rounded-full"
          />

          <h1 className="text-5xl mt-6 lg:mt-0 font-bold text-white lg:text-2xl lg:text-primary-1">
            પાર્ટી ની વિગતો
          </h1>
        </div>

        <div className="mt-[60px] px-10 py-5">
          <div className="lg:flex justify-between gap-4 items-center mb-4">
            <div className="w-full lg:w-fit lg:mt-4">
              <div className="flex justify-between">
                <p className="text-primary-1 font-bold">
                  બાકી પેમેન્ટ &nbsp;&nbsp;:{" "}
                </p>
                <p className="text-primary-1 font-bold">
                  &nbsp;&nbsp;{totalPendingAmount.toFixed(2) || 0.0}
                </p>
              </div>

              <div className="flex justify-between">
                <p className="text-primary-1 font-bold">આવેલ પેમેન્ટ :</p>
                <p className="text-primary-1 font-bold">
                  &nbsp;&nbsp;{totalReceivedAmount.toFixed(2) || 0.0}
                </p>
              </div>
            </div>

            <div className="mt-4 lg:mt-0 w-full lg:w-fit flex justify-between lg:justify-end gap-4">
              <button
                className="p-2 rounded-md flex items-center bg-gradient-to-r from-primary-1 to-primary-2 text-white transition-colors duration-300"
                onClick={() => setShowFilter(!showFilter)}
              >
                <FaSearch className="mr-2" />{" "}
                {showFilter ? "ફિલ્ટર છુપાવો" : "ફિલ્ટર બતાવો"}
              </button>
              <button
                className="bg-gradient-to-r from-primary-1 to-primary-2 text-white p-2 rounded-md flex items-center transition-colors duration-300"
                onClick={handleShowPartyForm}
              >
                <FaPlus className="mr-2" /> નવી પાર્ટી ઉમેરો
              </button>
            </div>
          </div>

          {showFilter && (
            <form onSubmit={handleSubmit(handleOnFilterSubmit)}>
              <div className="mb-4 bg-white shadow rounded p-4">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div className="flex items-center bg-gradient-to-r from-primary-1 to-primary-2 rounded pr-[2px] h-[44px]">
                    <FaCalendarAlt className="text-white mx-2" />
                    <input
                      className="bg-white text-primary-1 placeholder:text-primary-2 font-semibold text-xl focus:outline-none p-2 rounded flex-grow h-[40px]"
                      type="date"
                      {...register("fromDate")}
                      placeholder="From Date"
                    />
                  </div>

                  <div className="flex items-center bg-gradient-to-r from-primary-1 to-primary-2 rounded pr-[2px] h-[44px]">
                    <FaCalendarAlt className="text-white mx-2" />
                    <input
                      className="bg-white text-primary-1  placeholder:text-primary-2 font-semibold text-xl focus:outline-none p-2 rounded flex-grow h-[40px]"
                      type="date"
                      {...register("toDate")}
                      placeholder="To Date"
                    />
                  </div>

                  <div className="flex items-center bg-gradient-to-r from-primary-1 to-primary-2 rounded p-[2px] h-[44px]">
                    <input
                      {...register("name")}
                      className="text-primary-1 px-3 py-1 font-semibold text-xl placeholder:text-primary-2 w-full h-full rounded focus:outline-none"
                      placeholder="વિગતો દ્વારા શોધો"
                    />
                  </div>
                </div>
                <div className="flex gap-4 justify-end mt-4">
                  <button
                    onClick={handleClearFilter}
                    type="button"
                    className="bg-primary-1 hover:bg-primary-2 text-white px-4 py-2 rounded-md transition duration-300 ease-in-out"
                  >
                    હટાઓ
                  </button>

                  <button
                    type="submit"
                    className="bg-primary-1 hover:bg-primary-2 text-white px-4 py-2 rounded-md transition duration-300 ease-in-out"
                  >
                    ફિલ્ટર લાગુ કરો
                  </button>
                </div>
              </div>
            </form>
          )}

          <PartyList
            parties={parties}
            pageNumber={page}
            pageSize={pageSize}
            isLoading={isLoading}
            handleDeleteParty={handleDeleteParty}
          />

          <Pagination
            itemsPerPage={pageSize}
            totalItems={totalParties}
            paginate={paginate}
            currentPage={page}
          />
        </div>

        <PartyFormModal handleSaveParty={handleSaveParty} />

        {isPartiesItemModalOpen === true && (
          <ItemFormModal handleSavePartyItem={handleSavePartyItem} />
        )}
      </div>
    </Layout>
  );
};
export default PartiesPage;
