import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET } from "../../services/axios.service";
import { RootState } from "../../app/store";
import { ProcessesProps } from "../../types/process";

export interface ProcessesState {
  processes: ProcessesProps[];
  totalProcesses: number;
  isProcessModalOpen: boolean;
  editProcess: ProcessesProps | null;
  loading: boolean;
  error: string | null;
}

const initialState: ProcessesState = {
  processes: [],
  totalProcesses: 0,
  isProcessModalOpen: false,
  editProcess: null,
  loading: false,
  error: null,
};

type FetchProcessesListDataProps = {
  page: number;
  size?: number;
  fromDate?: string | null;
  toDate?: string | null;
  search?: string | null;
};

export const fetchProcessesListData = createAsyncThunk(
  "fetchProcessesListData",
  async (req: FetchProcessesListDataProps) => {
    let response: any;
    await GET(`/v1/admin/processes`, { ...req })
      .then((res: any) => {
        response = res;
      })
      .catch((err) => {
        response = err;
      });
    return response;
  }
);

export const processesSlice = createSlice({
  name: "processes",
  initialState,
  reducers: {
    toggleProcessModal: (state, action: PayloadAction<boolean>) => {
      state.isProcessModalOpen = action.payload;
    },
    setEditProcess: (state, action: PayloadAction<ProcessesProps | null>) => {
      state.editProcess = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProcessesListData.pending, (state, action: any) => {
      state.processes = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchProcessesListData.fulfilled, (state, action: any) => {
      state.loading = false;
      if (action.payload.data.status === "success") {
        state.processes = action.payload.data.data.records;
        state.totalProcesses = action.payload.data.data.total_records;
        state.error = null;
      } else {
        state.processes = [];
        state.error =
          action?.payload?.data?.message ||
          "કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.";
      }
    });
    builder.addCase(fetchProcessesListData.rejected, (state, action: any) => {
      state.processes = [];
      state.loading = false;
      state.error =
        action?.payload?.data?.message ||
        "કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.";
    });
  },
});

export const { toggleProcessModal, setEditProcess } = processesSlice.actions;

export const getProcesses = (state: RootState) => state.processes.processes;

export const getTotalProcesses = (state: RootState) =>
  state.processes.totalProcesses;

export const getIsProcessModalOpen = (state: RootState) =>
  state.processes.isProcessModalOpen;

export const getEditProcess = (state: RootState) => state.processes.editProcess;

export default processesSlice.reducer;
