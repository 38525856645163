import React, { useState } from "react";
import styles from "./PartyList.module.scss";

import moment from "moment";
import { PartiesProps } from "../../types/parties";
import { useAppDispatch } from "../../app/hooks";
import {
  setEditParty,
  togglePartyModal,
} from "../../features/parties/partiesSlice";
import {
  fetchItemsList,
  fetchPartiesItemListData,
  fetchProcessesList,
  togglePartiesItemModal,
} from "../../features/parties/partiesItemSlice";
import useConfirmPopup from "../../context/useConfirmPopup";
import { Link } from "react-router-dom";
import { route } from "../../static/route";

type Props = {
  parties: PartiesProps[];
  pageNumber: number;
  pageSize: number;
  isLoading: boolean;
  handleDeleteParty: (id: number) => void;
};

const PartyList = ({
  parties,
  pageNumber,
  pageSize,
  isLoading,
  handleDeleteParty,
}: Props) => {
  const dispatch = useAppDispatch();
  const confirmDelete = useConfirmPopup();

  const [isActionLoading, setIsActionLoading] = useState<number>(0);

  const handleEditParty = async (party: PartiesProps) => {
    await dispatch(setEditParty(party));
    dispatch(togglePartyModal(true));
  };

  const handleDeleteRow = async (id: number) => {
    const confirmed = await confirmDelete(
      `તમે આને પાછું ફેરવી શકશો નહીં!`,
      `શું તમે ખાતરી આપો છો?`,
      "હા, તેને કાઢી નાખો!"
    );

    if (confirmed) {
      setIsActionLoading(id);
      await handleDeleteParty(id);
      setIsActionLoading(0);
    }
  };

  const handleOpenItemModal = async (id: number) => {
    setIsActionLoading(id);
    await dispatch(fetchItemsList());
    await dispatch(fetchProcessesList());
    await dispatch(fetchPartiesItemListData(id));
    await dispatch(togglePartiesItemModal(true));
    setIsActionLoading(0);
  };

  return (
    <div className={styles.PartyList}>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    Sr. No.
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    પેઢી નું નામ
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    પાર્ટી નું નામ
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    ફોન નંબર
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-right text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    ઓર્ડર્સ
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-right text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    આઈટમ
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-right text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    બાકી પે.
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-right text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    આવેલ પે.
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    તારીખ
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-right text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    એકશન્સ બટન
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {isLoading ? (
                  <tr>
                    <td
                      colSpan={9}
                      className="text-primary-2 font-bold text-xl text-center py-2"
                    >
                      રાહ જુઓ...
                    </td>
                  </tr>
                ) : (
                  <>
                    {parties.length > 0 ? (
                      <>
                        {parties.map((party, index) => (
                          <tr key={index}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {(pageNumber - 1) * pageSize + index + 1}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {party.firm_name || "-"}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {party.name || "-"}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {party.phone || "-"}
                              {party.wp_phone && (
                                <>
                                  <br />
                                  WP: {party.wp_phone}
                                </>
                              )}
                            </td>
                            <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-900">
                              {party.orders_count || "-"}
                            </td>
                            <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-900">
                              {party.items_count || "-"}
                            </td>
                            <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-900">
                              {party?.total_amount?.toFixed(2) || "0.00"}
                            </td>
                            <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-900">
                              {party?.month_amount?.toFixed(2) || "0.00"}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {moment(party.created_at).format(
                                "DD-MM-YYYY hh:mm A"
                              )}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                              {isActionLoading === party.id ? (
                                <p className="text-primary-2 font-bold text-right text-lg">
                                  રાહ જુઓ...
                                </p>
                              ) : (
                                <>
                                  <Link
                                    to={route.payments.replace(
                                      ":id",
                                      party.id as unknown as string
                                    )}
                                    // target="_blank"
                                  >
                                    <a className="text-indigo-600 hover:text-indigo-900">
                                      Payment
                                    </a>
                                  </Link>
                                  <button
                                    onClick={() =>
                                      handleOpenItemModal(party.id)
                                    }
                                    className="text-indigo-600 hover:text-indigo-900 ml-4"
                                  >
                                    Items
                                  </button>
                                  <button
                                    onClick={() => handleEditParty(party)}
                                    className="text-indigo-600 hover:text-indigo-900 ml-4"
                                  >
                                    Edit
                                  </button>
                                  <button
                                    onClick={() => handleDeleteRow(party.id)}
                                    className="text-red-600 hover:text-red-900 ml-4"
                                  >
                                    Delete
                                  </button>
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td
                          colSpan={9}
                          className="text-primary-2 font-bold text-xl text-center py-2"
                        >
                          પાર્ટી ની વિગતો મળી નથી
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PartyList;
