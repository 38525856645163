import Swal, { SweetAlertResult } from "sweetalert2";
import { useCallback } from "react";

// Type for the function that the hook will return
type ConfirmAction = (
  title: string,
  message: string,
  buttonText: string,
  withPassword?: boolean
) => Promise<any>;

const useConfirmPopup = (): ConfirmAction => {
  const confirmAction = useCallback(
    (
      title: string,
      message: string,
      buttonText: string,
      withPassword: boolean = false
    ): Promise<any> => {
      return Swal.fire({
        title: title,
        text: message,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#6232c8",
        cancelButtonColor: "#d33",
        confirmButtonText: buttonText,
        cancelButtonText: "બંધ કરો",
        input: withPassword ? "password" : undefined,
        inputPlaceholder: withPassword
          ? "એડમીન નો પાસવર્ડ દાખલ કરો"
          : undefined,
        inputAttributes: withPassword ? { required: "required" } : undefined,
      }).then((result: SweetAlertResult) => {
        if (result.isConfirmed && withPassword) {
          return { confirmed: true, password: result.value as string };
        } else if (result.isConfirmed) {
          return true;
        }
        return false;
      });
    },
    []
  );

  return confirmAction;
};

export default useConfirmPopup;
