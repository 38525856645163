import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

import styles from "./NoteForm.module.scss";
import {
  getEditPayment,
  setEditPayment,
  toggleCreateModal,
} from "../../features/payments/paymentsSlice";
import { IFormInputNote, OptionsProps } from "../../types/payments";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { selectStyles } from "../../static/styles";

type Props = {
  handleSavePayment: (data: IFormInputNote) => void;
};

const NoteForm = ({ handleSavePayment }: Props) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const editPayment = useAppSelector(getEditPayment);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [noteTypeOption] = useState<OptionsProps[]>([
    {
      value: 0,
      label: "વધારાનું",
    },
    {
      value: 1,
      label: "રાઉન્ડ ઑફ",
    },
    {
      value: 2,
      label: "ટેક્સ અમાઉન્ટ",
    },
    {
      value: 3,
      label: "ક્યુઓલિટી ના લીધે રદ કરેલ",
    },
    {
      value: 4,
      label: "ઓછા નંગ ના લીધે રદ કરેલ",
    },
    {
      value: 5,
      label: "LB",
    },
  ]);
  const [noteStatusOption] = useState<OptionsProps[]>([
    {
      value: 0,
      label: "અમાઉન્ટ ઉમેરો",
    },
    {
      value: 1,
      label: "અમાઉન્ટ બાદ કરો",
    },
  ]);

  const {
    handleSubmit,
    register,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm<IFormInputNote>({
    defaultValues: { amount: null, note: null, status: null },
  });

  const handleOnSubmit: SubmitHandler<IFormInputNote> = async (data: any) => {
    setIsLoading(true);
    const note =
      noteTypeOption?.find((n) => n.value === data.note)?.label || null;

    if (editPayment && editPayment.id) {
      await handleSavePayment({
        ...data,
        id: editPayment.id,
        type: 2,
        note: note,
      });
    } else {
      await handleSavePayment({ ...data, type: 2, party_id: id, note: note });
    }
    handleCloseFormModal();
    setIsLoading(false);
  };

  const handleCloseFormModal = () => {
    reset();
    dispatch(toggleCreateModal(0));
    dispatch(setEditPayment(null));
  };

  useEffect(() => {
    if (editPayment && editPayment.id) {
      const note =
        noteTypeOption?.find((n) => n.label === editPayment.note)?.value ||
        null;

      reset({
        amount: editPayment.amount,
        note: note,
        status: editPayment.type,
      });
    } else {
      reset({ amount: null, note: null, status: null });
    }
  }, [editPayment]);

  return (
    <div className={styles.NoteForm}>
      <div className="relative h-[100vh] w-[100vw] overflow-y-scroll lg:overflow-auto top-0 lg:top-10 lg:mx-auto p-5 border lg:w-96 lg:h-fit shadow-lg lg:rounded-md bg-white">
        <div className="mt-3">
          <h3 className="text-2xl text-center font-bold leading-6 text-primary-1">
            વધ ઘટ ની માહિતી
          </h3>
          <div className="mt-2 py-3">
            <form onSubmit={handleSubmit(handleOnSubmit)}>
              <div className="grid gap-4">
                <div className="mt-6">
                  <label className="text-primary-1 font-bold">
                    વધ ઘટ ટાઈપ :
                  </label>
                  <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-11 w-full relative rounded">
                    <Controller
                      {...register("note", {
                        required: true,
                        min: 0,
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          value={
                            noteTypeOption.find(
                              (val) => val.value === field.value
                            ) || null
                          }
                          className="text-primary-1 p-0 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                          styles={selectStyles}
                          placeholder={"ટાઈપ નું નામ સિલેક્ટ કરો"}
                          options={noteTypeOption}
                          onChange={(val: any) => setValue("note", val?.value)}
                        />
                      )}
                    />
                  </div>

                  {errors.note && (
                    <span className="text-red-500">
                      ટાઈપ નું નામ સિલેક્ટ કરો
                    </span>
                  )}
                </div>

                <div className="mt-3">
                  <label className="text-primary-1 font-bold">સ્ટેટ્સ :</label>
                  <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-11 w-full relative rounded">
                    <Controller
                      {...register("status", {
                        required: true,
                        min: 0,
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          value={
                            noteStatusOption.find(
                              (val) => val.value === field.value
                            ) || null
                          }
                          className="text-primary-1 p-0 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                          styles={selectStyles}
                          placeholder={"સ્ટેટ્સ નું નામ સિલેક્ટ કરો"}
                          options={noteStatusOption}
                          onChange={(val: any) =>
                            setValue("status", val?.value)
                          }
                        />
                      )}
                    />
                  </div>

                  {errors.status && (
                    <span className="text-red-500">
                      સ્ટેટ્સ નું નામ સિલેક્ટ કરો
                    </span>
                  )}
                </div>

                <div className="mt-3">
                  <label className="text-primary-1 font-bold">
                    વધ ઘટ નું અમાઉન્ટ :
                  </label>
                  <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-11 w-full relative rounded">
                    <input
                      className="text-primary-1 px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                      type="number"
                      pattern="-?[0-9]*"
                      step={"any"}
                      placeholder="વધ ઘટ નું અમાઉન્ટ દાખલ કરો"
                      {...register("amount", {
                        required: true,
                        min: 0,
                      })}
                      onFocus={(e) =>
                        e.target.addEventListener(
                          "wheel",
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                    />
                  </div>
                  {errors.amount && (
                    <span className="text-red-500">
                      વધ ઘટ નું અમાઉન્ટ દાખલ કરો
                    </span>
                  )}
                </div>

                <div className="mt-3 text-center">
                  <button
                    className="mx-auto shadow disabled:opacity-50 rounded-3xl text-3xl lg:text-xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? "રાહ જુઓ..." : "આગળ વધો"}
                  </button>
                </div>

                <div className="mt-1 text-center">
                  <button
                    className="mx-auto shadow disabled:opacity-50 rounded-3xl text-3xl lg:text-xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
                    type="button"
                    onClick={handleCloseFormModal}
                    disabled={isLoading}
                  >
                    બંધ કરો
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoteForm;
