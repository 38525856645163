import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

import styles from "./OrdersFormModal.module.scss";
import { IFormInput, OptionsProps } from "../../types/orders";
import Select from "react-select";
import {
  getEditOrder,
  getEditPartyItems,
  toggleOrderModal,
} from "../../features/orders/ordersSlice";
import { selectStyles } from "../../static/styles";
import { FaCalendarAlt } from "react-icons/fa";
import moment from "moment";

type Props = {
  handleSaveOrder: (data: IFormInput) => void;
};

const OrdersFormModal = ({ handleSaveOrder }: Props) => {
  const dispatch = useAppDispatch();
  const editOrder = useAppSelector(getEditOrder);
  const editPartyItems = useAppSelector(getEditPartyItems);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [itemOption, setItemOption] = useState<OptionsProps[]>([]);
  const [processOption, setProcessOption] = useState<OptionsProps[]>([]);
  const [statusOption] = useState<OptionsProps[]>([
    {
      value: 0,
      label: "ચાલુ કામ",
    },
    {
      value: 1,
      label: "પૂરું થયેલું કામ",
    },
  ]);

  const {
    handleSubmit,
    register,
    reset,
    control,
    setValue,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm<IFormInput>();

  const handleOnSubmit: SubmitHandler<IFormInput> = async (data: any) => {
    setIsLoading(true);
    await handleSaveOrder(data);
    handleCloseFormModal();
    setIsLoading(false);
  };

  const handleCloseFormModal = () => {
    reset();
    dispatch(toggleOrderModal(false));
  };

  const handleChangeItem = (itemId: number) => {
    if (itemId) {
      setValue("item_id", itemId);
      clearErrors("item_id");
      setValue("process_id", 0);
    }
  };

  const handleChangeProcess = (processId: number, itemId: number) => {
    if (processId && itemId) {
      setValue("process_id", processId);
      clearErrors("process_id");

      const rate =
        editPartyItems.find(
          (i) => i.item_id === itemId && i.process_id === processId
        )?.rate || 0;

      setValue("rate", rate);
    }
  };

  useEffect(() => {
    if (editOrder && editOrder.id) {
      reset({
        item_id: editOrder.item_id,
        process_id: editOrder.process_id,
        qty: editOrder.qty,
        delivered_qty: editOrder.delivered_qty,
        status: editOrder.status,
        rate: editOrder.rate,
        created_at: moment(editOrder.created_at).format("YYYY-MM-DDTHH:mm"),
      });
    } else {
      reset();
    }
  }, [editOrder]);

  useEffect(() => {
    const [options1, options2] = editPartyItems.reduce<
      [OptionsProps[], OptionsProps[]]
    >(
      ([acc1, acc2], item) => {
        if (!acc1.some((o) => o.value === item.item_id)) {
          acc1.push({ value: item.item_id, label: item.item.name });
        }

        acc2.push({
          value: item.process_id,
          label: item.process.name,
          primary_id: item.item_id,
        });

        return [acc1, acc2];
      },
      [[], []]
    );

    setItemOption(options1);
    setProcessOption(options2);
  }, [editPartyItems]);

  return (
    <div className={styles.OrdersFormModal}>
      <div className="relative h-[100vh] w-[100vw] overflow-y-scroll lg:overflow-auto top-0 lg:top-10 lg:mx-auto p-5 border lg:w-96 lg:h-fit shadow-lg lg:rounded-md bg-white">
        <div className="mt-3">
          <h3 className="text-2xl text-center font-bold leading-6 text-primary-1">
            ઓર્ડર ની માહિતી
          </h3>
          <div className="mt-2 py-3">
            <form onSubmit={handleSubmit(handleOnSubmit)}>
              <div className="grid gap-4">
                <div className="mt-6">
                  <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-12 w-full relative rounded">
                    <input
                      className="text-primary-1 focus:outline-none px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                      type="text"
                      placeholder="ઓર્ડરનુ નામ દાખલ કરો"
                      readOnly
                      value={editOrder?.party.firm_name}
                    />
                  </div>
                </div>

                <div className="mt-1">
                  <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-12 w-full relative rounded">
                    <Controller
                      {...register("item_id", {
                        required: true,
                        min: 1,
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          value={
                            itemOption.find(
                              (val) => val.value === field.value
                            ) || null
                          }
                          className="text-primary-1 p-0 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                          styles={selectStyles}
                          placeholder={"આઈટમ નું નામ દાખલ કરો"}
                          options={itemOption}
                          onChange={(val: any) => handleChangeItem(val?.value)}
                        />
                      )}
                    />
                  </div>

                  {errors?.item_id && (
                    <span className="text-red-500">
                      આઈટમ નું નામ સિલેક્ટ કરો
                    </span>
                  )}
                </div>

                <div className="mt-1">
                  <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-12 w-full relative rounded">
                    <Controller
                      {...register("process_id", {
                        required: true,
                        min: 1,
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          value={
                            processOption.find(
                              (val) => val.value === field?.value
                            ) || null
                          }
                          className="text-primary-1 p-0 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                          styles={selectStyles}
                          placeholder={"પ્રોસેસ નું નામ દાખલ કરો"}
                          options={processOption?.filter((process) => {
                            const item_id = getValues("item_id");
                            if (item_id && item_id === process.primary_id)
                              return process;
                          })}
                          onChange={(val: any) =>
                            handleChangeProcess(val?.value, val?.primary_id)
                          }
                        />
                      )}
                    />
                  </div>

                  {errors?.process_id && (
                    <span className="text-red-500">
                      પ્રોસેસ નું નામ સિલેક્ટ કરો
                    </span>
                  )}
                </div>

                <div className="mt-1">
                  <label className="text-primary-1 font-bold">
                    નંગ ની સંખ્યા :
                  </label>
                  <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-14 w-full relative rounded">
                    <input
                      className="text-primary-1 focus:outline-none px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                      type="number"
                      pattern="[0-9]*"
                      placeholder="નંગ ની સંખ્યા દાખલ કરો"
                      {...register("qty", { required: true })}
                      onFocus={(e) =>
                        e.target.addEventListener(
                          "wheel",
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                    />
                  </div>
                  {errors.qty && errors.qty.type === "required" && (
                    <span className="text-red-500">નંગ ની સંખ્યા દાખલ કરો</span>
                  )}
                </div>

                <div className="mt-1">
                  <label className="text-primary-1 font-bold">
                    પરત નંગ ની સંખ્યા :
                  </label>
                  <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-14 w-full relative rounded">
                    <input
                      className="text-primary-1 focus:outline-none px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                      type="number"
                      pattern="[0-9]*"
                      placeholder="પરત નંગ ની સંખ્યા દાખલ કરો"
                      {...register("delivered_qty", { required: true })}
                      onFocus={(e) =>
                        e.target.addEventListener(
                          "wheel",
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                    />
                  </div>
                  {errors.delivered_qty &&
                    errors.delivered_qty.type === "required" && (
                      <span className="text-red-500">
                        પરત નંગ ની સંખ્યા દાખલ કરો
                      </span>
                    )}
                </div>

                <div className="mt-1">
                  <label className="text-primary-1 font-bold">
                    પ્રોસેસ નો ભાવ :
                  </label>
                  <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-14 w-full relative rounded">
                    <input
                      className="text-primary-1 focus:outline-none px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                      type="number"
                      pattern="-?[0-9]*"
                      step={"any"}
                      placeholder="પ્રોસેસ નો ભાવ દાખલ કરો"
                      {...register("rate", { required: true })}
                      onFocus={(e) =>
                        e.target.addEventListener(
                          "wheel",
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                    />
                  </div>
                  {errors.rate && errors.rate.type === "required" && (
                    <span className="text-red-500">
                      પ્રોસેસ નો ભાવ દાખલ કરો
                    </span>
                  )}
                </div>

                <div className="mt-1">
                  <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-12 w-full relative rounded">
                    <Controller
                      {...register("status")}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          value={
                            statusOption.find(
                              (val) => val.value === field.value
                            ) || null
                          }
                          className="text-primary-1 p-0 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                          styles={selectStyles}
                          placeholder={"સ્ટેટ્સ નું નામ દાખલ કરો"}
                          options={statusOption}
                          onChange={(val: any) =>
                            setValue("status", val?.value)
                          }
                        />
                      )}
                    />
                  </div>

                  {errors?.status && (
                    <span className="text-red-500">
                      સ્ટેટ્સ નું નામ સિલેક્ટ કરો
                    </span>
                  )}
                </div>

                <div className="mt-1">
                  <div className="flex items-center bg-gradient-to-r from-primary-1 to-primary-2 rounded pr-[2px] h-[44px]">
                    <FaCalendarAlt className="text-white mx-2" />
                    <input
                      className="bg-white text-primary-1  placeholder:text-primary-2 font-semibold text-xl focus:outline-none p-2 rounded flex-grow h-[40px]"
                      type="datetime-local"
                      {...register("created_at", { required: true })}
                      placeholder="ઓર્ડર ની તારીખ"
                    />
                  </div>

                  {errors?.created_at && (
                    <span className="text-red-500">
                      ઓર્ડર ની તારીખ સિલેક્ટ કરો
                    </span>
                  )}
                </div>

                <div className="mt-3 text-center">
                  <button
                    className="mx-auto shadow disabled:opacity-50 rounded-3xl text-3xl lg:text-xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? "રાહ જુઓ..." : "આગળ વધો"}
                  </button>
                </div>

                <div className="mt-1 text-center">
                  <button
                    className="mx-auto shadow disabled:opacity-50 rounded-3xl text-3xl lg:text-xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
                    type="button"
                    onClick={handleCloseFormModal}
                    disabled={isLoading}
                  >
                    બંધ કરો
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrdersFormModal;
