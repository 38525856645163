import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ItemsProps } from "../../types/item";
import { GET } from "../../services/axios.service";
import { ProcessesProps } from "../../types/process";
import { PartiesItemProps, PartiesProps } from "../../types/parties";
import { RootState } from "../../app/store";

export interface PartiesItemState {
  isPartiesItemModalOpen: boolean;
  party: PartiesProps | null;
  partiesItem: PartiesItemProps[];
  items: ItemsProps[];
  processes: ProcessesProps[];
  loading: boolean;
  error: string | null;
}

const initialState: PartiesItemState = {
  isPartiesItemModalOpen: false,
  party: null,
  partiesItem: [],
  items: [],
  processes: [],
  loading: false,
  error: null,
};

export const fetchItemsList = createAsyncThunk(
  "fetchItemsListForPartyItem",
  async () => {
    let response: any;
    await GET(`/v1/admin/items`, { all: true })
      .then((res: any) => {
        response = res;
      })
      .catch((err) => {
        response = err;
      });
    return response;
  }
);

export const fetchProcessesList = createAsyncThunk(
  "fetchProcessesListForPartyItem",
  async () => {
    let response: any;
    await GET(`/v1/admin/processes`, { all: true })
      .then((res: any) => {
        response = res;
      })
      .catch((err) => {
        response = err;
      });
    return response;
  }
);

export const fetchPartiesItemListData = createAsyncThunk(
  "fetchPartiesItemListData",
  async (id: number) => {
    let response: any;
    await GET(`/v1/admin/parties/${id}/items`, {})
      .then((res: any) => {
        response = res;
      })
      .catch((err) => {
        response = err;
      });
    return response;
  }
);

export const partiesItemSlice = createSlice({
  name: "partiesItem",
  initialState,
  reducers: {
    togglePartiesItemModal: (state, action: PayloadAction<boolean>) => {
      state.isPartiesItemModalOpen = action.payload;
      if (!action.payload) {
        state.party = null;
        state.partiesItem = [];
        state.items = [];
        state.processes = [];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchItemsList.pending, (state, action: any) => {
      state.items = [];
    });
    builder.addCase(fetchItemsList.fulfilled, (state, action: any) => {
      if (action.payload.data.status === "success") {
        state.items = action.payload.data.data.records;
      } else {
        state.items = [];
      }
    });
    builder.addCase(fetchItemsList.rejected, (state, action: any) => {
      state.items = [];
    });
    builder.addCase(fetchProcessesList.pending, (state, action: any) => {
      state.processes = [];
    });
    builder.addCase(fetchProcessesList.fulfilled, (state, action: any) => {
      if (action.payload.data.status === "success") {
        state.processes = action.payload.data.data.records;
      } else {
        state.processes = [];
      }
    });
    builder.addCase(fetchProcessesList.rejected, (state, action: any) => {
      state.processes = [];
    });
    builder.addCase(fetchPartiesItemListData.pending, (state, action: any) => {
      state.party = null;
      state.partiesItem = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      fetchPartiesItemListData.fulfilled,
      (state, action: any) => {
        state.loading = false;
        if (action.payload.data.status === "success") {
          state.party = action.payload.data.data.party_info;
          state.partiesItem = action.payload.data.data.party_items;
          state.error = null;
        } else {
          state.party = null;
          state.partiesItem = [];
          state.error =
            action?.payload?.data?.message ||
            "કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.";
        }
      }
    );
    builder.addCase(fetchPartiesItemListData.rejected, (state, action: any) => {
      state.party = null;
      state.partiesItem = [];
      state.loading = false;
      state.error =
        action?.payload?.data?.message ||
        "કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.";
    });
  },
});

export const { togglePartiesItemModal } = partiesItemSlice.actions;

export const getParty = (state: RootState) => state.partiesItem.party;

export const getItems = (state: RootState) => state.partiesItem.items;

export const getProcesses = (state: RootState) => state.partiesItem.processes;

export const getPartiesItem = (state: RootState) =>
  state.partiesItem.partiesItem;

export const getIsPartiesItemModalOpen = (state: RootState) =>
  state.partiesItem.isPartiesItemModalOpen;

export default partiesItemSlice.reducer;
