import React, { useEffect, useState } from "react";
import Layout from "../../components/layout";
import { FaArrowLeft, FaPlus, FaSearch, FaCalendarAlt } from "react-icons/fa";

import styles from "./UsersPage.module.scss";
import { useNavigate } from "react-router-dom";
import { route } from "../../static/route";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  fetchUsersListData,
  getEditUser,
  getUsers,
  getTotalUsers,
  setEditUser,
  toggleUserModal,
} from "../../features/users/usersSlice";
import UserList from "../../components/users/UserList";
import Pagination from "../../components/pagination";
import UserFormModal from "../../components/users/UserFormModal";
import { IFormInput } from "../../types/users";
import { toast } from "react-toastify";
import { toastStyles } from "../../static/styles";
import { DELETE, POST, PUT } from "../../services/axios.service";
import { useForm } from "react-hook-form";

const UsersPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const users = useAppSelector(getUsers);
  const totalUsers = useAppSelector(getTotalUsers);
  const editUser = useAppSelector(getEditUser);

  const [page, setPage] = useState<number>(1);
  const [pageSize] = useState<number>(10);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const hanldeGoToHome = () => {
    navigate(route.dashboard);
  };

  const paginate = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const handleShowUserForm = async () => {
    await dispatch(setEditUser(null));
    dispatch(toggleUserModal(true));
  };

  const handleSaveUser = async (data: IFormInput) => {
    try {
      if (editUser && editUser.id) {
        await PUT(`/v1/admin/users/${editUser.id}`, {
          name: data.name,
          email: data.email,
          phone: data.phone,
          password: data.password,
          role: data.role,
        })
          .then(async (response: any) => {
            if (response.data.status === "success") {
              await dispatch(setEditUser(null));

              if (page === 1) {
                fetchUsersList(page, pageSize);
              } else {
                setPage(1);
              }

              toast.success(response.data.message, {
                ...(toastStyles as any),
              });
            } else {
              toast.error(response.data.message, {
                ...(toastStyles as any),
              });
            }
          })
          .catch((error: any) => {
            toast.error(error.data.message, {
              ...(toastStyles as any),
            });
          });
      } else {
        await POST("/v1/admin/users", {
          name: data.name,
          email: data.email,
          phone: data.phone,
          password: data.password,
          role: data.role,
        })
          .then(async (response: any) => {
            if (response.data.status === "success") {
              if (page === 1) {
                fetchUsersList(page, pageSize);
              } else {
                setPage(1);
              }

              toast.success(response.data.message, {
                ...(toastStyles as any),
              });
            } else {
              toast.error(response.data.message, {
                ...(toastStyles as any),
              });
            }
          })
          .catch((error: any) => {
            toast.error(error.data.message, {
              ...(toastStyles as any),
            });
          });
      }
    } catch (error) {
      toast.error("કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.", {
        ...(toastStyles as any),
      });
    }
  };

  const handleDeleteUser = async (id: number) => {
    try {
      await DELETE(`/v1/admin/users/${id}`)
        .then(async (response: any) => {
          if (response.data.status === "success") {
            const pageNumber = users.length > 1 ? page : page - 1;
            if (page === pageNumber) {
              fetchUsersList(page, pageSize);
            } else {
              setPage(pageNumber);
            }

            toast.success(response.data.message, {
              ...(toastStyles as any),
            });
          } else {
            toast.error(response.data.message, {
              ...(toastStyles as any),
            });
          }
        })
        .catch((error: any) => {
          toast.error(error.data.message, {
            ...(toastStyles as any),
          });
        });
    } catch (error) {
      toast.error("કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.", {
        ...(toastStyles as any),
      });
    }
  };

  const fetchUsersList = async (page: number, size: number) => {
    setIsLoading(true);

    dispatch(
      fetchUsersListData({
        page: page,
        size: size,
      })
    ).then(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetchUsersList(page, pageSize);
  }, [page]);

  return (
    <Layout>
      <div className={styles.UsersPage}>
        <div className="logoSection">
          <h1
            onClick={hanldeGoToHome}
            className="text-5xl font-bold text-white lg:text-3xl cursor-pointer lg:w-fit"
          >
            કનૈયા પ્લેટિંગ
          </h1>

          <hr className="w-[180px] border-white border-[4px] rounded-[4px] mx-auto mt-4 lg:mx-0 lg:w-24 lg:border-[2px] lg:mt-2" />
        </div>

        <div className="pageTitleSection">
          <FaArrowLeft
            onClick={hanldeGoToHome}
            className="lg:hidden absolute top-5 left-5 text-3xl text-white border-2 border-white p-1 rounded-full"
          />

          <h1 className="text-5xl mt-6 lg:mt-0 font-bold text-white lg:text-2xl lg:text-primary-1">
            કામદાર ની વિગતો
          </h1>
        </div>

        <div className="mt-[60px] px-10 py-5">
          <div className="flex justify-between lg:justify-end gap-4 items-center mb-4">
            <button
              className="bg-gradient-to-r from-primary-1 to-primary-2 text-white p-2 rounded-md flex items-center transition-colors duration-300"
              onClick={handleShowUserForm}
            >
              <FaPlus className="mr-2" /> નવો કામદાર ઉમેરો
            </button>
          </div>

          <UserList
            users={users}
            pageNumber={page}
            pageSize={pageSize}
            isLoading={isLoading}
            handleDeleteUser={handleDeleteUser}
          />

          <Pagination
            itemsPerPage={pageSize}
            totalItems={totalUsers}
            paginate={paginate}
            currentPage={page}
          />
        </div>

        <UserFormModal handleSaveUser={handleSaveUser} />
      </div>
    </Layout>
  );
};
export default UsersPage;
