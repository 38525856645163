import React, { useEffect } from "react";

import styles from "./Layout.module.scss";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchLoggingUserData } from "../../features/loggingUser/loggingUserSlice";
import ImgModal from "../modals/ImgModal";
import { getIsImgModalOpen } from "../../features/settings/settingsSlice";

type Props = {
  children: React.ReactNode;
  className?: string;
};

const Layout = (props: Props) => {
  const dispatch = useAppDispatch();
  const authToken = localStorage.getItem("authToken");
  const isImgModalOpen = useAppSelector(getIsImgModalOpen);

  useEffect(() => {
    if (authToken) dispatch(fetchLoggingUserData());
  }, [authToken]);

  useEffect(() => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  }, []);

  return (
    <div className={`${styles.Layout} ${props.className}`}>
      <div className="mainBackground"></div>
      <div
        className={`mainContent ${isImgModalOpen && "bg-primary-2 opacity-20"}`}
      >
        {props.children}
      </div>
      <ImgModal />
    </div>
  );
};
export default Layout;
