import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET } from "../../services/axios.service";
import { RootState } from "../../app/store";
import { PartiesProps } from "../../types/parties";

export interface PartiesState {
  parties: PartiesProps[];
  totalParties: number;
  totalPendingAmount: number;
  totalReceivedAmount: number;
  isPartyModalOpen: boolean;
  editParty: PartiesProps | null;
  loading: boolean;
  error: string | null;
}

const initialState: PartiesState = {
  parties: [],
  totalParties: 0,
  totalPendingAmount: 0,
  totalReceivedAmount: 0,
  isPartyModalOpen: false,
  editParty: null,
  loading: false,
  error: null,
};

type FetchPartiesListDataProps = {
  page: number;
  size?: number;
  fromDate?: string | null;
  toDate?: string | null;
  search?: string | null;
};

export const fetchPartiesListData = createAsyncThunk(
  "fetchPartiesListData",
  async (req: FetchPartiesListDataProps) => {
    let response: any;
    await GET(`/v1/admin/parties`, { ...req })
      .then((res: any) => {
        response = res;
      })
      .catch((err) => {
        response = err;
      });
    return response;
  }
);

export const partiesSlice = createSlice({
  name: "parties",
  initialState,
  reducers: {
    togglePartyModal: (state, action: PayloadAction<boolean>) => {
      state.isPartyModalOpen = action.payload;
    },
    setEditParty: (state, action: PayloadAction<PartiesProps | null>) => {
      state.editParty = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPartiesListData.pending, (state, action: any) => {
      state.parties = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchPartiesListData.fulfilled, (state, action: any) => {
      state.loading = false;
      if (action.payload.data.status === "success") {
        state.parties = action.payload.data.data.records;
        state.totalParties = action.payload.data.data.total_records;
        state.totalPendingAmount =
          action.payload.data.data.total_pending_amount;
        state.totalReceivedAmount =
          action.payload.data.data.total_received_amount;
        state.error = null;
      } else {
        state.parties = [];
        state.totalPendingAmount = 0;
        state.totalReceivedAmount = 0;
        state.error =
          action?.payload?.data?.message ||
          "કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.";
      }
    });
    builder.addCase(fetchPartiesListData.rejected, (state, action: any) => {
      state.parties = [];
      state.loading = false;
      state.error =
        action?.payload?.data?.message ||
        "કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.";
    });
  },
});

export const { togglePartyModal, setEditParty } = partiesSlice.actions;

export const getParties = (state: RootState) => state.parties.parties;

export const getTotalParties = (state: RootState) => state.parties.totalParties;

export const getTotalPendingAmount = (state: RootState) =>
  state.parties.totalPendingAmount;

export const getTotalReceivedAmount = (state: RootState) =>
  state.parties.totalReceivedAmount;

export const getIsPartyModalOpen = (state: RootState) =>
  state.parties.isPartyModalOpen;

export const getEditParty = (state: RootState) => state.parties.editParty;

export default partiesSlice.reducer;
