import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getEditParty,
  getIsPartyModalOpen,
  togglePartyModal,
} from "../../features/parties/partiesSlice";

import styles from "./PartyFormModal.module.scss";
import { IFormInput } from "../../types/parties";

type Props = {
  handleSaveParty: (data: IFormInput) => void;
};

const PartyFormModal = ({ handleSaveParty }: Props) => {
  const dispatch = useAppDispatch();
  const editParty = useAppSelector(getEditParty);
  const isPartyModalOpen = useAppSelector(getIsPartyModalOpen);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: {
      firm_name: "",
      name: "",
      phone: "",
      wp_phone: "",
      address: "",
    },
  });

  const handleOnSubmit: SubmitHandler<IFormInput> = async (data: any) => {
    setIsLoading(true);
    await handleSaveParty(data);
    handleCloseFormModal();
    setIsLoading(false);
  };

  const handleCloseFormModal = () => {
    reset();
    dispatch(togglePartyModal(false));
  };

  useEffect(() => {
    if (editParty && editParty.id) {
      reset({
        firm_name: editParty.firm_name,
        name: editParty.name,
        phone: editParty.phone,
        wp_phone: editParty.wp_phone,
        address: editParty.address,
      });
    } else {
      reset({ firm_name: "", name: "", phone: "", wp_phone: "", address: "" });
    }
  }, [editParty, isPartyModalOpen]);

  return (
    <>
      {isPartyModalOpen === true && (
        <div className={styles.PartyFormModal}>
          <div className="relative h-[100vh] w-[100vw] overflow-y-scroll lg:overflow-auto top-0 lg:top-10 lg:mx-auto p-5 border lg:w-96 lg:h-fit shadow-lg lg:rounded-md bg-white">
            <div className="mt-3">
              <h3 className="text-2xl text-center font-bold leading-6 text-primary-1">
                પાર્ટી ની માહિતી
              </h3>
              <div className="mt-2 py-3">
                <form onSubmit={handleSubmit(handleOnSubmit)}>
                  <div className="grid gap-4">
                    <div className="mt-6">
                      <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-14 w-full relative rounded">
                        <input
                          className="text-primary-1 focus:outline-none px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                          type="text"
                          placeholder="પેઢીનું નામ દાખલ કરો"
                          {...register("firm_name", { required: true })}
                        />
                      </div>
                      {errors.firm_name &&
                        errors.firm_name.type === "required" && (
                          <span className="text-red-500">
                            પેઢીનું નામ દાખલ કરો
                          </span>
                        )}
                    </div>

                    <div className="mt-3">
                      <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-14 w-full relative rounded">
                        <input
                          className="text-primary-1 focus:outline-none px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                          type="text"
                          placeholder="પાર્ટીનું નામ દાખલ કરો"
                          {...register("name", { required: false })}
                        />
                      </div>
                      {errors.name && errors.name.type === "required" && (
                        <span className="text-red-500">
                          પાર્ટીનું નામ દાખલ કરો
                        </span>
                      )}
                    </div>

                    <div className="mt-3">
                      <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-14 w-full relative rounded">
                        <input
                          className="text-primary-1 focus:outline-none px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                          type="text"
                          placeholder="મોબાઇલ નંબર દાખલ કરો"
                          {...register("phone", { required: false })}
                        />
                      </div>
                      {errors.phone && errors.phone.type === "required" && (
                        <span className="text-red-500">
                          મોબાઇલ નંબર દાખલ કરો
                        </span>
                      )}
                    </div>

                    <div className="mt-3">
                      <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-14 w-full relative rounded">
                        <input
                          className="text-primary-1 focus:outline-none px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                          type="text"
                          placeholder="વહાર્ટસપ નંબર દાખલ કરો"
                          {...register("wp_phone", { required: false })}
                        />
                      </div>
                      {errors.wp_phone &&
                        errors.wp_phone.type === "required" && (
                          <span className="text-red-500">
                            વહાર્ટસપ નંબર દાખલ કરો
                          </span>
                        )}
                    </div>

                    <div className="mt-3">
                      <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-28 w-full relative rounded">
                        <textarea
                          rows={5}
                          className="text-primary-1 focus:outline-none px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                          placeholder="પાર્ટીનુ એડ્રેસ દાખલ કરો"
                          {...register("address", { required: false })}
                        />
                      </div>
                      {errors.address && errors.address.type === "required" && (
                        <span className="text-red-500">
                          પાર્ટીનુ એડ્રેસ દાખલ કરો
                        </span>
                      )}
                    </div>

                    <div className="mt-3 text-center">
                      <button
                        className="mx-auto shadow disabled:opacity-50 rounded-3xl text-3xl lg:text-xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
                        type="submit"
                        disabled={isLoading}
                      >
                        {isLoading ? "રાહ જુઓ..." : "આગળ વધો"}
                      </button>
                    </div>

                    <div className="mt-1 text-center">
                      <button
                        className="mx-auto shadow disabled:opacity-50 rounded-3xl text-3xl lg:text-xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
                        type="button"
                        onClick={handleCloseFormModal}
                        disabled={isLoading}
                      >
                        બંધ કરો
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PartyFormModal;
