import React from "react";
import Layout from "../../components/layout";
import { Link } from "react-router-dom";

import styles from "./NotFoundPage.module.scss";
import { route } from "../../static/route";

const NotFoundPage = () => {
  return (
    <Layout>
      <div className={styles.NotFoundPage}>
        <div className="logoSection">
          <h1 className="text-5xl font-bold text-white lg:text-3xl">
            કનૈયા પ્લેટિંગ
          </h1>

          <hr className="w-[180px] border-white border-[4px] rounded-[4px] mx-auto mt-4 lg:mx-0 lg:w-24 lg:border-[2px] lg:mt-2" />
        </div>
        <div className="mt-[70px] px-10 py-5 text-center">
          <p className="mt-4 text-primary-2 font-bold text-2xl">
            પૃષ્ઠ મળ્યું નથી (404)
          </p>
          <p className="mt-4 text-primary-2 font-semibold text-lg">
            માફ કરશો, તમે જે પૃષ્ઠ શોધી રહ્યા છો તે અમારી વેબસાઇટ પર ઉપલબ્ધ નથી.
            કદાચ તમે જે URL દાખલ કર્યું તે ખોટું છે અથવા પૃષ્ઠને અમે હટાવી દીધું
            છે.
          </p>
          <p className="mt-4 text-primary-2 font-semibold text-lg">
            મુખ્ય પૃષ્ઠ પર જાઓ: અમારી વેબસાઇટનું મુખ્ય પૃષ્ઠ જોવા માટે <br />
            <Link to={route.home}>
              <a className="text-xl font-bold underline">અહીં ક્લિક કરો.</a>
            </Link>
          </p>
        </div>
      </div>
    </Layout>
  );
};
export default NotFoundPage;
