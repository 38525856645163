import React, { useState } from "react";
import styles from "./EmployeeList.module.scss";

import moment from "moment";
import { useAppDispatch } from "../../app/hooks";
import useConfirmPopup from "../../context/useConfirmPopup";
import { EmployeesProps } from "../../types/employees";
import {
  setEditEmployee,
  toggleEmployeeModal,
} from "../../features/employees/employeesSlice";
import { Link } from "react-router-dom";
import { route } from "../../static/route";

type Props = {
  employees: EmployeesProps[];
  pageNumber: number;
  pageSize: number;
  isLoading: boolean;
  handleDeleteEmployee: (id: number) => void;
};

const EmployeeList = ({
  employees,
  pageNumber,
  pageSize,
  isLoading,
  handleDeleteEmployee,
}: Props) => {
  const dispatch = useAppDispatch();
  const confirmDelete = useConfirmPopup();

  const [isActionLoading, setIsActionLoading] = useState<number>(0);

  const handleEditEmployee = async (employee: EmployeesProps) => {
    await dispatch(setEditEmployee(employee));
    dispatch(toggleEmployeeModal(true));
  };

  const handleDeleteRow = async (id: number) => {
    const confirmed = await confirmDelete(
      `તમે આને પાછું ફેરવી શકશો નહીં!`,
      `શું તમે ખાતરી આપો છો?`,
      "હા, તેને કાઢી નાખો!"
    );

    if (confirmed) {
      setIsActionLoading(id);
      await handleDeleteEmployee(id);
      setIsActionLoading(0);
    }
  };

  return (
    <div className={styles.EmployeeList}>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    Sr. No.
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    કાર્ડ નંબર
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    કામદાર નું નામ
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    ફોન નંબર
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-right text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    પગાર
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    તારીખ
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-right text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    એકશન્સ બટન
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {isLoading ? (
                  <tr>
                    <td
                      colSpan={9}
                      className="text-primary-2 font-bold text-xl text-center py-2"
                    >
                      રાહ જુઓ...
                    </td>
                  </tr>
                ) : (
                  <>
                    {employees.length > 0 ? (
                      <>
                        {employees.map((employee, index) => (
                          <tr key={index}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {(pageNumber - 1) * pageSize + index + 1}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {employee.employee_code || "-"}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {employee.name || "-"}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {employee.phone || "-"}
                            </td>
                            <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-900">
                              {employee?.amount?.toFixed(2) || "0.00"}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {moment(employee.created_at).format(
                                "DD-MM-YYYY hh:mm A"
                              )}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                              {isActionLoading === employee.id ? (
                                <p className="text-primary-2 font-bold text-right text-lg">
                                  રાહ જુઓ...
                                </p>
                              ) : (
                                <>
                                  <Link
                                    to={route.salaries.replace(
                                      ":id",
                                      employee.id as unknown as string
                                    )}
                                    // target="_blank"
                                  >
                                    <a className="text-indigo-600 hover:text-indigo-900">
                                      Salary
                                    </a>
                                  </Link>
                                  <button
                                    onClick={() => handleEditEmployee(employee)}
                                    className="text-indigo-600 hover:text-indigo-900 ml-4"
                                  >
                                    Edit
                                  </button>
                                  <button
                                    onClick={() => handleDeleteRow(employee.id)}
                                    className="text-red-600 hover:text-red-900 ml-4"
                                  >
                                    Delete
                                  </button>
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td
                          colSpan={9}
                          className="text-primary-2 font-bold text-xl text-center py-2"
                        >
                          પાર્ટી ની વિગતો મળી નથી
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EmployeeList;
