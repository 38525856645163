import React, { useEffect, useState } from "react";
import Layout from "../../components/layout";
import { FaArrowLeft, FaPlus, FaSearch, FaCalendarAlt } from "react-icons/fa";

import styles from "./PaymentsPage.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { route } from "../../static/route";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useForm } from "react-hook-form";
import {
  fetchPaymentsListData,
  getEditPayment,
  getIsCreateModalOpen,
  getParty,
  getPayments,
  getTotalAmount,
  getTotalInvoice,
  getTotalNoteAmount,
  getTotalPayments,
  getTotalPaymnet,
  toggleCreateModal,
} from "../../features/payments/paymentsSlice";
import { toastStyles } from "../../static/styles";
import CreateForm from "../../components/payments/CreateForm";
import { toast } from "react-toastify";
import { DELETE, GET, POST, PUT } from "../../services/axios.service";
import Pagination from "../../components/pagination";
import PaymentList from "../../components/payments/PaymentList";
import EditForm from "../../components/payments/EditForm";
import PaymentForm from "../../components/payments/PaymentForm";
import NoteForm from "../../components/payments/NoteForm";

const PaymentsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isCreateModalOpen = useAppSelector(getIsCreateModalOpen);
  const payments = useAppSelector(getPayments);
  const totalPayments = useAppSelector(getTotalPayments);
  const party = useAppSelector(getParty);
  const totalInvoice = useAppSelector(getTotalInvoice);
  const totalPaymnet = useAppSelector(getTotalPaymnet);
  const totalNoteAmount = useAppSelector(getTotalNoteAmount);
  const totalAmount = useAppSelector(getTotalAmount);
  const editPayment = useAppSelector(getEditPayment);

  interface FilterFormInput {
    fromDate: string;
    toDate: string;
  }

  const { handleSubmit, register, reset, getValues } =
    useForm<FilterFormInput>();

  const [page, setPage] = useState<number>(1);
  const [pageSize] = useState<number>(50);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState(false);

  const hanldeGoToHome = () => {
    navigate(route.dashboard);
  };

  const hanldeGoToBack = () => {
    navigate(-1);
  };

  const handleOnFilterSubmit = async () => {
    fetchPaymentList(page, pageSize);
  };

  const handleClearFilter = () => {
    reset();
    fetchPaymentList(page, pageSize);
  };

  const handleShowCreateForm = async (type: number) => {
    dispatch(toggleCreateModal(type));
  };

  const handleSavePayment = async (data: any) => {
    try {
      if (data && data.id) {
        await PUT(`/v1/admin/payments/${data.id}`, { ...data })
          .then(async (response: any) => {
            if (response.data.status === "success") {
              if (page === 1) {
                fetchPaymentList(page, pageSize);
              } else {
                setPage(1);
              }

              toast.success(response.data.message, {
                ...(toastStyles as any),
              });
            } else {
              toast.error(response.data.message, {
                ...(toastStyles as any),
              });
            }
          })
          .catch((error: any) => {
            toast.error(error.data.message, {
              ...(toastStyles as any),
            });
          });
      } else {
        await POST("/v1/admin/payments", { ...data })
          .then(async (response: any) => {
            if (response.data.status === "success") {
              if (page === 1) {
                fetchPaymentList(page, pageSize);
              } else {
                setPage(1);
              }

              toast.success(response.data.message, {
                ...(toastStyles as any),
              });
            } else {
              toast.error(response.data.message, {
                ...(toastStyles as any),
              });
            }
          })
          .catch((error: any) => {
            toast.error(error.data.message, {
              ...(toastStyles as any),
            });
          });
      }
    } catch (error) {
      toast.error("કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.", {
        ...(toastStyles as any),
      });
    }
  };

  const handleDeletePayment = async (id: number, password: string) => {
    try {
      await DELETE(`/v1/admin/payments/${id}`, {
        password: password,
      })
        .then(async (response: any) => {
          if (response.data.status === "success") {
            const pageNumber = payments.length > 1 ? page : page - 1;
            if (page === pageNumber) {
              fetchPaymentList(page, pageSize);
            } else {
              setPage(pageNumber);
            }

            toast.success(response.data.message, {
              ...(toastStyles as any),
            });
          } else {
            toast.error(response.data.message, {
              ...(toastStyles as any),
            });
          }
        })
        .catch((error: any) => {
          toast.error(error.data.message, {
            ...(toastStyles as any),
          });
        });
    } catch (error) {
      toast.error("કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.", {
        ...(toastStyles as any),
      });
    }
  };

  const handleExportReport = async () => {
    try {
      const data = getValues();

      await GET(`/v1/admin/payments/parties/${id}/reports`, {
        fromDate: data?.fromDate || null,
        toDate: data?.toDate || null,
      })
        .then(async (response: any) => {
          if (response.data.status === "success") {
            toast.success(response.data.message, {
              ...(toastStyles as any),
            });

            const downloadLink = response.data.data.download_link;
            try {
              const newWindow = window.open(downloadLink, "_blank");
              if (
                !newWindow ||
                newWindow.closed ||
                typeof newWindow.closed == "undefined"
              ) {
                throw new Error("Pop-up blocked");
              }
            } catch (e) {
              window.location.href = downloadLink;
            }
          } else {
            toast.error(response.data.message, {
              ...(toastStyles as any),
            });
          }
        })
        .catch((error: any) => {
          toast.error(error.data.message, {
            ...(toastStyles as any),
          });
        });
    } catch (error) {
      toast.error("કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.", {
        ...(toastStyles as any),
      });
    }
  };

  const fetchPaymentList = async (page: number, size: number) => {
    const data = getValues();

    setIsLoading(true);

    dispatch(
      fetchPaymentsListData({
        id: id || "0",
        queryParams: {
          page: page,
          size: size,
          fromDate: data?.fromDate || null,
          toDate: data?.toDate || null,
        },
      })
    ).then(() => {
      setIsLoading(false);
    });
  };

  const paginate = (pageNumber: number) => {
    setPage(pageNumber);
  };

  useEffect(() => {
    fetchPaymentList(page, pageSize);
  }, [page]);

  return (
    <Layout>
      <div className={styles.PaymentsPage}>
        <div className="logoSection">
          <h1
            onClick={hanldeGoToHome}
            className="text-5xl font-bold text-white lg:text-3xl cursor-pointer lg:w-fit"
          >
            કનૈયા પ્લેટિંગ
          </h1>

          <hr className="w-[180px] border-white border-[4px] rounded-[4px] mx-auto mt-4 lg:mx-0 lg:w-24 lg:border-[2px] lg:mt-2" />
        </div>

        <div className="pageTitleSection">
          <FaArrowLeft
            onClick={hanldeGoToBack}
            className="lg:top-20 cursor-pointer absolute top-5 left-5 text-3xl text-white border-2 border-white p-1 rounded-full"
          />

          <h1 className="text-5xl mt-6 lg:mt-0 font-bold text-white lg:text-2xl lg:text-primary-1">
            પેમેન્ટ ની વિગતો
          </h1>

          <p className="mt-4 text-white font-semibold text-xl lg:text-lg lg:mt-1 lg:text-primary-2">
            {party?.firm_name || ""}{" "}
            {party && party?.phone && <span>+91 {party?.phone || ""}</span>}
          </p>
        </div>

        <div className="mt-[60px] px-10 py-5">
          <div className="flex justify-between lg:justify-end gap-4 items-center mb-4">
            <button
              className="p-2 rounded-md flex items-center bg-gradient-to-r from-primary-1 to-primary-2 text-white transition-colors duration-300"
              onClick={() => setShowFilter(!showFilter)}
            >
              <FaSearch className="mr-2" />{" "}
              {showFilter ? "ફિલ્ટર છુપાવો" : "ફિલ્ટર બતાવો"}
            </button>
            <button
              onClick={() => handleShowCreateForm(1)}
              className="bg-gradient-to-r from-primary-1 to-primary-2 text-white p-2 rounded-md flex items-center transition-colors duration-300"
            >
              <FaPlus className="mr-2" />
              હિસાબ
            </button>
            <button
              onClick={() => handleShowCreateForm(2)}
              className="bg-gradient-to-r from-primary-1 to-primary-2 text-white p-2 rounded-md flex items-center transition-colors duration-300"
            >
              <FaPlus className="mr-2" />
              પેમેન્ટ
            </button>
            <button
              onClick={() => handleShowCreateForm(3)}
              className="bg-gradient-to-r from-primary-1 to-primary-2 text-white p-2 rounded-md flex items-center transition-colors duration-300"
            >
              <FaPlus className="mr-2" />
              વધ ઘટ
            </button>
          </div>

          {showFilter && (
            <form onSubmit={handleSubmit(handleOnFilterSubmit)}>
              <div className="mb-4 bg-white shadow rounded p-4">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div className="flex items-center bg-gradient-to-r from-primary-1 to-primary-2 rounded pr-[2px] h-[44px]">
                    <FaCalendarAlt className="text-white mx-2" />
                    <input
                      className="bg-white text-primary-1 placeholder:text-primary-2 font-semibold text-xl focus:outline-none p-2 rounded flex-grow h-[40px]"
                      type="date"
                      {...register("fromDate")}
                      placeholder="From Date"
                    />
                  </div>

                  <div className="flex items-center bg-gradient-to-r from-primary-1 to-primary-2 rounded pr-[2px] h-[44px]">
                    <FaCalendarAlt className="text-white mx-2" />
                    <input
                      className="bg-white text-primary-1  placeholder:text-primary-2 font-semibold text-xl focus:outline-none p-2 rounded flex-grow h-[40px]"
                      type="date"
                      {...register("toDate")}
                      placeholder="To Date"
                    />
                  </div>
                </div>
                <div className="flex gap-4 justify-end mt-4">
                  <button
                    onClick={handleClearFilter}
                    type="button"
                    className="bg-primary-1 hover:bg-primary-2 text-white px-4 py-2 rounded-md transition duration-300 ease-in-out"
                  >
                    હટાઓ
                  </button>

                  <button
                    onClick={handleExportReport}
                    type="button"
                    className="bg-primary-1 hover:bg-primary-2 text-white px-4 py-2 rounded-md transition duration-300 ease-in-out"
                  >
                    રિપોર્ટ બનાવો
                  </button>

                  <button
                    type="submit"
                    className="bg-primary-1 hover:bg-primary-2 text-white px-4 py-2 rounded-md transition duration-300 ease-in-out"
                  >
                    ફિલ્ટર લાગુ કરો
                  </button>
                </div>
              </div>
            </form>
          )}

          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg mb-4">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="min-w-fit text-nowrap px-6 py-3 text-center text-base text-primary-1 font-bold uppercase tracking-wider"
                      >
                        ટોટલ હિસાબ
                      </th>
                      <th
                        scope="col"
                        className="min-w-fit text-nowrap px-6 py-3 text-center text-base text-primary-1 font-bold uppercase tracking-wider"
                      >
                        ટોટલ આવેલ પેમેન્ટ
                      </th>
                      <th
                        scope="col"
                        className="min-w-fit text-nowrap px-6 py-3 text-center text-base text-primary-1 font-bold uppercase tracking-wider"
                      >
                        ટોટલ વધ ઘટ
                      </th>
                      <th
                        scope="col"
                        className="min-w-fit text-nowrap px-6 py-3 text-center text-base text-primary-1 font-bold uppercase tracking-wider"
                      >
                        બાકી રહેતું પેમેન્ટ
                      </th>
                    </tr>
                  </thead>

                  <tbody className="bg-white divide-y divide-gray-200">
                    <tr>
                      <td className="text-center px-6 py-4 whitespace-nowrap text-sm text-primary-1 font-bold mt-auto">
                        {totalInvoice.toFixed(2) || "0.00"}
                      </td>
                      <td className="text-center px-6 py-4 whitespace-nowrap text-sm text-primary-1 font-bold mt-auto">
                        {totalPaymnet.toFixed(2) || "0.00"}
                      </td>
                      <td className="text-center px-6 py-4 whitespace-nowrap text-sm text-primary-1 font-bold mt-auto">
                        {totalNoteAmount.toFixed(2) || "0.00"}
                      </td>
                      <td className="text-center px-6 py-4 whitespace-nowrap text-sm text-primary-1 font-bold mt-auto">
                        {totalAmount.toFixed(2) || "0.00"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <PaymentList
            payments={payments}
            pageNumber={page}
            pageSize={pageSize}
            isLoading={isLoading}
            handleDeletePayment={handleDeletePayment}
          />

          <Pagination
            itemsPerPage={pageSize}
            totalItems={totalPayments}
            paginate={paginate}
            currentPage={page}
          />

          {isCreateModalOpen === 1 && editPayment === null && (
            <CreateForm handleCreatePayment={handleSavePayment} />
          )}

          {isCreateModalOpen === 1 && editPayment !== null && (
            <EditForm handleEditPayment={handleSavePayment} />
          )}

          {isCreateModalOpen === 2 && (
            <PaymentForm handleSavePayment={handleSavePayment} />
          )}

          {isCreateModalOpen === 3 && (
            <NoteForm handleSavePayment={handleSavePayment} />
          )}
        </div>
      </div>
    </Layout>
  );
};
export default PaymentsPage;
