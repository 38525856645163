import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getEditUser,
  getIsUserModalOpen,
  toggleUserModal,
} from "../../features/users/usersSlice";

import Select from "react-select";
import styles from "./UserFormModal.module.scss";
import { IFormInput, OptionsProps } from "../../types/users";
import { selectStyles } from "../../static/styles";

type Props = {
  handleSaveUser: (data: IFormInput) => void;
};

const UserFormModal = ({ handleSaveUser }: Props) => {
  const dispatch = useAppDispatch();
  const editUser = useAppSelector(getEditUser);
  const isUserModalOpen = useAppSelector(getIsUserModalOpen);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [roleOption] = useState<OptionsProps[]>([
    {
      value: 0,
      label: "કામદાર",
    },
    {
      value: 1,
      label: "એડમીન",
    },
  ]);

  const {
    handleSubmit,
    register,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: { name: "", email: "", phone: "", password: "", role: null },
  });

  const handleOnSubmit: SubmitHandler<IFormInput> = async (data: any) => {
    setIsLoading(true);
    await handleSaveUser(data);
    handleCloseFormModal();
    setIsLoading(false);
  };

  const handleCloseFormModal = () => {
    reset();
    dispatch(toggleUserModal(false));
  };

  useEffect(() => {
    if (editUser && editUser.id) {
      const role = editUser && editUser.role === "Worker" ? 0 : 1;
      reset({
        name: editUser.name,
        email: editUser.email,
        phone: editUser.phone,
        password: "",
        role: role,
      });
    } else {
      reset({ name: "", email: "", phone: "", password: "", role: null });
    }
  }, [editUser, isUserModalOpen]);

  return (
    <>
      {isUserModalOpen === true && (
        <div className={styles.UserFormModal}>
          <div className="relative h-[100vh] w-[100vw] overflow-y-scroll lg:overflow-auto top-0 lg:top-10 lg:mx-auto p-5 border lg:w-96 lg:h-fit shadow-lg lg:rounded-md bg-white">
            <div className="mt-3">
              <h3 className="text-2xl text-center font-bold leading-6 text-primary-1">
                કામદાર ની માહિતી
              </h3>
              <div className="mt-2 py-3">
                <form onSubmit={handleSubmit(handleOnSubmit)}>
                  <div className="grid gap-4">
                    <div className="mt-6">
                      <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-14 w-full relative rounded">
                        <input
                          className="text-primary-1 focus:outline-none px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                          type="text"
                          placeholder="કામદારનુ નામ દાખલ કરો"
                          {...register("name", { required: true })}
                        />
                      </div>
                      {errors.name && errors.name.type === "required" && (
                        <span className="text-red-500">
                          કામદારનુ નામ દાખલ કરો
                        </span>
                      )}
                    </div>

                    <div className="mt-1">
                      <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-14 w-full relative rounded">
                        <input
                          className="text-primary-1 focus:outline-none px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                          type="text"
                          placeholder="કામદારનુ ઇમેઇલ દાખલ કરો"
                          {...register("email", { required: true })}
                        />
                      </div>
                      {errors.email && errors.email.type === "required" && (
                        <span className="text-red-500">
                          કામદારનુ ઇમેઇલ દાખલ કરો
                        </span>
                      )}
                    </div>

                    <div className="mt-1">
                      <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-14 w-full relative rounded">
                        <input
                          className="text-primary-1 focus:outline-none px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                          type="text"
                          placeholder="કામદારનુ ફોન નં. દાખલ કરો"
                          {...register("phone", { required: true })}
                        />
                      </div>
                      {errors.phone && errors.phone.type === "required" && (
                        <span className="text-red-500">
                          કામદારનુ ફોન નં. દાખલ કરો
                        </span>
                      )}
                    </div>

                    <div className="mt-1">
                      <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-14 w-full relative rounded">
                        <input
                          className="text-primary-1 focus:outline-none px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                          type="text"
                          placeholder="કામદારનુ પાસવર્ડ દાખલ કરો"
                          {...register("password", {
                            required: editUser && editUser.id ? false : true,
                          })}
                        />
                      </div>
                      {errors.password &&
                        errors.password.type === "required" && (
                          <span className="text-red-500">
                            કામદારનુ પાસવર્ડ દાખલ કરો
                          </span>
                        )}
                    </div>

                    <div className="mt-1">
                      <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-12 w-full relative rounded">
                        <Controller
                          {...register("role", {
                            required: true,
                            min: 0,
                          })}
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              value={
                                roleOption.find(
                                  (val) => val.value === field?.value
                                ) || null
                              }
                              className="text-primary-1 p-0 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                              styles={selectStyles}
                              placeholder={"રોલ નું નામ દાખલ કરો"}
                              options={roleOption}
                              onChange={(val: any) =>
                                setValue("role", val?.value)
                              }
                            />
                          )}
                        />
                      </div>

                      {errors?.role && (
                        <span className="text-red-500">
                          રોલ નું નામ સિલેક્ટ કરો
                        </span>
                      )}
                    </div>

                    <div className="mt-3 text-center">
                      <button
                        className="mx-auto shadow disabled:opacity-50 rounded-3xl text-3xl lg:text-xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
                        type="submit"
                        disabled={isLoading}
                      >
                        {isLoading ? "રાહ જુઓ..." : "આગળ વધો"}
                      </button>
                    </div>

                    <div className="mt-1 text-center">
                      <button
                        className="mx-auto shadow disabled:opacity-50 rounded-3xl text-3xl lg:text-xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
                        type="button"
                        onClick={handleCloseFormModal}
                        disabled={isLoading}
                      >
                        બંધ કરો
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserFormModal;
