import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET } from "../../services/axios.service";
import { RootState } from "../../app/store";
import { EmployeesProps } from "../../types/employees";

export interface EmployeesState {
  employees: EmployeesProps[];
  totalEmployees: number;
  isEmployeeModalOpen: boolean;
  editEmployee: EmployeesProps | null;
  loading: boolean;
  error: string | null;
}

const initialState: EmployeesState = {
  employees: [],
  totalEmployees: 0,
  isEmployeeModalOpen: false,
  editEmployee: null,
  loading: false,
  error: null,
};

type FetchEmployeesListDataProps = {
  page: number;
  size?: number;
  fromDate?: string | null;
  toDate?: string | null;
  search?: string | null;
};

export const fetchEmployeesListData = createAsyncThunk(
  "fetchEmployeesListData",
  async (req: FetchEmployeesListDataProps) => {
    let response: any;
    await GET(`/v1/admin/employees`, { ...req })
      .then((res: any) => {
        response = res;
      })
      .catch((err) => {
        response = err;
      });
    return response;
  }
);

export const employeesSlice = createSlice({
  name: "employees",
  initialState,
  reducers: {
    toggleEmployeeModal: (state, action: PayloadAction<boolean>) => {
      state.isEmployeeModalOpen = action.payload;
    },
    setEditEmployee: (state, action: PayloadAction<EmployeesProps | null>) => {
      state.editEmployee = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEmployeesListData.pending, (state, action: any) => {
      state.employees = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchEmployeesListData.fulfilled, (state, action: any) => {
      state.loading = false;
      if (action.payload.data.status === "success") {
        state.employees = action.payload.data.data.records;
        state.totalEmployees = action.payload.data.data.total_records;
        state.error = null;
      } else {
        state.employees = [];
        state.error =
          action?.payload?.data?.message ||
          "કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.";
      }
    });
    builder.addCase(fetchEmployeesListData.rejected, (state, action: any) => {
      state.employees = [];
      state.loading = false;
      state.error =
        action?.payload?.data?.message ||
        "કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.";
    });
  },
});

export const { toggleEmployeeModal, setEditEmployee } = employeesSlice.actions;

export const getEmployees = (state: RootState) => state.employees.employees;

export const getTotalEmployees = (state: RootState) =>
  state.employees.totalEmployees;

export const getIsEmployeeModalOpen = (state: RootState) =>
  state.employees.isEmployeeModalOpen;

export const getEditEmployee = (state: RootState) =>
  state.employees.editEmployee;

export default employeesSlice.reducer;
