import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GET } from "../../services/axios.service";
import { RootState } from "../../app/store";
import { EmployeesProps } from "../../types/employees";
import { SalariesProps } from "../../types/salaries";

export interface SalariesState {
  employee: EmployeesProps;
  salaries: SalariesProps[];
  totalRecords: number;
  totalSalary: number;
  totalBonus: number;
  totalWithdrawal: number;
  totalPayment: number;
  totalAmount: number;
  isCreateModalOpen: number;
  editSalary: SalariesProps | null;
  loading: boolean;
  error: string | null;
}

const initialState: SalariesState = {
  employee: {} as EmployeesProps,
  salaries: [],
  totalRecords: 0,
  totalSalary: 0,
  totalBonus: 0,
  totalWithdrawal: 0,
  totalPayment: 0,
  totalAmount: 0,
  isCreateModalOpen: 0,
  editSalary: null,
  loading: false,
  error: null,
};

type FetchSalariesListDataProps = {
  id: string;
  queryParams: {
    page: number;
    size?: number;
    fromDate?: string | null;
    toDate?: string | null;
  };
};

export const fetchSalariesListData = createAsyncThunk(
  "fetchSalariesListData",
  async (req: FetchSalariesListDataProps) => {
    let response: any;
    await GET(`/v1/admin/salaries/${req.id}`, { ...req.queryParams })
      .then((res: any) => {
        response = res;
      })
      .catch((err) => {
        response = err;
      });
    return response;
  }
);

export const salariesSlice = createSlice({
  name: "salaries",
  initialState,
  reducers: {
    toggleCreateModal: (state, action: PayloadAction<number>) => {
      state.isCreateModalOpen = action.payload;
    },
    setEditSalary: (state, action: PayloadAction<SalariesProps | null>) => {
      state.editSalary = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSalariesListData.pending, (state, action: any) => {
      state.salaries = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchSalariesListData.fulfilled, (state, action: any) => {
      state.loading = false;
      if (action.payload.data.status === "success") {
        state.employee = action.payload.data.data.employee;
        state.salaries = action.payload.data.data.records;
        state.totalRecords = action.payload.data.data.total_records;
        state.totalSalary = action.payload.data.data.total_salary;
        state.totalBonus = action.payload.data.data.total_bonus;
        state.totalWithdrawal = action.payload.data.data.total_withdrawal;
        state.totalPayment = action.payload.data.data.total_payment;
        state.totalAmount = action.payload.data.data.total_amount;
        state.error = null;
      } else {
        state.employee = {} as EmployeesProps;
        state.salaries = [];
        state.totalSalary = 0;
        state.totalBonus = 0;
        state.totalWithdrawal = 0;
        state.totalPayment = 0;
        state.totalAmount = 0;
        state.error =
          action?.payload?.data?.message ||
          "કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.";
      }
    });
    builder.addCase(fetchSalariesListData.rejected, (state, action: any) => {
      state.salaries = [];
      state.loading = false;
      state.error =
        action?.payload?.data?.message ||
        "કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.";
    });
  },
});

export const { toggleCreateModal, setEditSalary } = salariesSlice.actions;

export const getEmployee = (state: RootState) => state.salaries.employee;

export const getSalaries = (state: RootState) => state.salaries.salaries;

export const getTotalSalary = (state: RootState) => state.salaries.totalSalary;

export const getTotalBonus = (state: RootState) => state.salaries.totalBonus;

export const getTotalWithdrawal = (state: RootState) =>
  state.salaries.totalWithdrawal;

export const getTotalPayment = (state: RootState) =>
  state.salaries.totalPayment;

export const getTotalAmount = (state: RootState) => state.salaries.totalAmount;

export const getIsCreateModalOpen = (state: RootState) =>
  state.salaries.isCreateModalOpen;

export const getEditSalary = (state: RootState) => state.salaries.editSalary;

export const getTotalRecords = (state: RootState) =>
  state.salaries.totalRecords;

export default salariesSlice.reducer;
