import React, { useEffect, useState } from "react";

import styles from "./ItemFormModal.module.scss";

import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import Select from "react-select";
import {
  ItemFormItem,
  ItemFormValues,
  OptionsProps,
} from "../../types/parties";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getItems,
  getPartiesItem,
  getParty,
  togglePartiesItemModal,
} from "../../features/parties/partiesItemSlice";
import { selectStyles } from "../../static/styles";
import { GiCancel } from "react-icons/gi";
import ProcessForm from "./ProcessForm";

type Props = {
  handleSavePartyItem: (id: number, data: ItemFormItem[]) => void;
};

const ItemFormModal = ({ handleSavePartyItem }: Props) => {
  const dispatch = useAppDispatch();
  const party = useAppSelector(getParty);
  const items = useAppSelector(getItems);
  const partiesItem = useAppSelector(getPartiesItem);

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<ItemFormValues>({
    defaultValues: {
      party_items: [
        {
          item_id: 0,
          processes: [{ process_id: 0, rate: null }],
        },
      ],
    },
  });

  const {
    fields: itemFields,
    append: appendItem,
    remove: removeItem,
  } = useFieldArray({
    control,
    name: "party_items",
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [itemOption, setItemOption] = useState<OptionsProps[]>([]);

  const onSubmit: SubmitHandler<ItemFormValues> = async (data) => {
    if (party?.id) {
      setIsLoading(true);
      await handleSavePartyItem(party?.id, data.party_items);
      setIsLoading(false);
    }
  };

  const handleCloseFormModal = () => {
    reset();
    dispatch(togglePartiesItemModal(false));
  };

  const handleChangeItem = (itemId: number, index: number) => {
    if (!itemFields.some((i) => i.item_id === itemId)) {
      setValue(`party_items.${index}.item_id`, itemId);
      clearErrors(`party_items.${index}.item_id`);
    }
  };

  useEffect(() => {
    const options =
      items?.map((item) => ({ value: item.id, label: item.name })) ?? [];
    setItemOption(options);
  }, [items]);

  useEffect(() => {
    const partyItemsData: ItemFormItem[] =
      partiesItem?.map((i) => ({
        item_id: i.item_id,
        processes:
          i.processes?.map((p) => ({
            process_id: p.process_id,
            rate: p.rate,
          })) ?? [],
      })) ?? [];

    reset({ party_items: partyItemsData });
  }, [partiesItem]);

  return (
    <div className={styles.ItemFormModal}>
      <div className="relative h-[100vh] w-[100vw] overflow-y-scroll lg:overflow-auto top-0 lg:top-10 lg:mx-auto p-5 border lg:w-[80vw] lg:h-fit shadow-lg lg:rounded-md bg-white">
        <div className="mt-3">
          <h3 className="text-2xl mt-10 lg:mt-0 text-center lg:text-left font-bold leading-6 text-primary-1">
            આઈટમ ની માહિતી - {party?.firm_name || ""}
          </h3>

          <GiCancel
            onClick={handleCloseFormModal}
            className="absolute cursor-pointer z-10 shadow bg-white top-3 right-5 text-3xl lg:text-xl text-primary-1 border-2 border-primary-1 p-1 lg:p-0 rounded-full"
          />
          <div className="mt-2 py-3 lg:py-2">
            <form onSubmit={handleSubmit(onSubmit)} className="p-4 pb-0">
              {itemFields.length <= 0 && (
                <h2 className="text-primary-2 font-bold text-xl text-center py-2">
                  કોઈ આઈટમ મળી નથી, નવી ઉમેરવા માટે આઈટમ ઉંમેરો બટન પર ક્લિક કરો
                </h2>
              )}

              {itemFields.map((item, index) => (
                <div
                  key={item.id}
                  className="mb-4 border-2 border-primary-1 rounded-md p-4"
                >
                  <h2 className="relative w-full text-2xl lg:text-xl underline text-primary-1 font-bold mb-4">
                    આઈટમ - {index + 1}
                    <GiCancel
                      onClick={() => removeItem(index)}
                      className="absolute cursor-pointer z-10 shadow bg-white top-1 right-1 text-3xl lg:text-xl text-primary-1 border-2 border-primary-1 p-1 lg:p-0 rounded-full"
                    />
                  </h2>

                  <div className="mt-6">
                    <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-12 w-full relative rounded">
                      <Controller
                        {...register(`party_items.${index}.item_id`, {
                          required: true,
                          min: 1,
                        })}
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            value={
                              itemOption.find(
                                (val) => val.value === field.value
                              ) || null
                            }
                            className="text-primary-1 p-0 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                            styles={selectStyles}
                            placeholder={"આઈટમ નું નામ દાખલ કરો"}
                            options={itemOption}
                            onChange={(val: any) =>
                              handleChangeItem(val?.value, index)
                            }
                          />
                        )}
                      />
                    </div>

                    {errors?.party_items?.length !== undefined &&
                      errors.party_items[index]?.item_id !== undefined && (
                        <span className="text-red-500">
                          આઈટમ નું નામ સિલેક્ટ કરો
                        </span>
                      )}
                  </div>

                  <ProcessForm
                    nestIndex={index}
                    control={control}
                    register={register}
                    errors={errors}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    isLoading={isLoading}
                  />
                </div>
              ))}

              <div className="text-center">
                <button
                  className="mx-auto shadow disabled:opacity-50 rounded-3xl text-3xl lg:text-xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
                  type="button"
                  onClick={() =>
                    appendItem({
                      item_id: 0,
                      processes: [{ process_id: 0, rate: null }],
                    })
                  }
                  disabled={isLoading}
                >
                  આઈટમ ઉંમેરો
                </button>
              </div>

              <div className="grid lg:flex gap-3 mt-3 justify-center">
                <div className="text-center">
                  <button
                    className="mx-auto shadow disabled:opacity-50 rounded-3xl text-3xl lg:text-xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? "રાહ જુઓ..." : "આગળ વધો"}
                  </button>
                </div>

                <div className="text-center">
                  <button
                    className="mx-auto shadow disabled:opacity-50 rounded-3xl text-3xl lg:text-xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
                    type="button"
                    onClick={handleCloseFormModal}
                    disabled={isLoading}
                  >
                    બંધ કરો
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemFormModal;
