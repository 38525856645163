import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";
import loggingUserSlice from "../features/loggingUser/loggingUserSlice";
import settingsSlice from "../features/settings/settingsSlice";
import itemsSlice from "../features/items/itemsSlice";
import processesSlice from "../features/processes/processesSlice";
import partiesSlice from "../features/parties/partiesSlice";
import partiesItemSlice from "../features/parties/partiesItemSlice";
import ordersSlice from "../features/orders/ordersSlice";
import paymentsSlice from "../features/payments/paymentsSlice";
import usersSlice from "../features/users/usersSlice";
import employeesSlice from "../features/employees/employeesSlice";
import salariesSlice from "../features/salaries/salariesSlice";

export const store = configureStore({
  reducer: {
    loggingUser: loggingUserSlice,
    settings: settingsSlice,
    items: itemsSlice,
    processes: processesSlice,
    parties: partiesSlice,
    partiesItem: partiesItemSlice,
    orders: ordersSlice,
    payments: paymentsSlice,
    users: usersSlice,
    employees: employeesSlice,
    salaries: salariesSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
