import React, { useState } from "react";
import styles from "./OrderList.module.scss";

import moment from "moment";
import { OrdersProps } from "../../types/orders";
import { useAppDispatch } from "../../app/hooks";
import {
  fetchEditPartyItemsList,
  setEditOrder,
  toggleOrderModal,
  toggleOrderPhotoModal,
} from "../../features/orders/ordersSlice";
import useConfirmPopup from "../../context/useConfirmPopup";

type Props = {
  orders: OrdersProps[];
  pageNumber: number;
  pageSize: number;
  isLoading: boolean;
  handleDeleteOrder: (id: number, password: string) => void;
};

const OrderList = ({
  orders,
  pageNumber,
  pageSize,
  isLoading,
  handleDeleteOrder,
}: Props) => {
  const dispatch = useAppDispatch();
  const confirmDelete = useConfirmPopup();

  const [isActionLoading, setIsActionLoading] = useState<number>(0);

  const handleEditOrder = async (order: OrdersProps) => {
    await dispatch(fetchEditPartyItemsList(order.party_id));
    await dispatch(setEditOrder(order));
    dispatch(toggleOrderModal(true));
  };

  const handleDeleteRow = async (id: number) => {
    const response = await confirmDelete(
      `તમે આને પાછું ફેરવી શકશો નહીં!`,
      `શું તમે ખાતરી આપો છો?`,
      "હા, તેને કાઢી નાખો!",
      true
    );

    if (response.confirmed) {
      setIsActionLoading(id);
      await handleDeleteOrder(id, response.password);
      setIsActionLoading(0);
    }
  };

  const handleOpenPhoto = async (order: OrdersProps) => {
    dispatch(toggleOrderPhotoModal(order.image));
  };

  return (
    <div className={styles.OrderList}>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    Sr. No.
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    ઓર્ડર ID
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    પાર્ટી
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    આઈટમ
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    પ્રોસેસ
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    નંગ
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    પરત નંગ
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    ટોટલ રૂ
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    સ્ટેટ્સ
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    તારીખ
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-right text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    એકશન્સ બટન
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {isLoading ? (
                  <tr>
                    <td
                      colSpan={11}
                      className="text-primary-2 font-bold text-xl text-center py-2"
                    >
                      રાહ જુઓ...
                    </td>
                  </tr>
                ) : (
                  <>
                    {orders.length > 0 ? (
                      <>
                        {orders.map((order, index) => (
                          <tr key={index}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {(pageNumber - 1) * pageSize + index + 1}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {order.id || "-"}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {order?.party?.firm_name || "-"}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {order?.item?.name || "-"}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {order?.process?.name || "-"}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {order?.qty || "-"}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {order?.delivered_qty || "-"}
                            </td>
                            <td className="text-right px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {((order?.qty || 0) * (order?.rate || 0)).toFixed(
                                2
                              ) || "-"}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {(order?.status === 1
                                ? "પૂરું કામ"
                                : "ચાલુ કામ") || "-"}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {moment(order.created_at).format(
                                "DD-MM-YYYY hh:mm A"
                              )}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                              {isActionLoading === order.id ? (
                                <p className="text-primary-2 font-bold text-right text-lg">
                                  રાહ જુઓ...
                                </p>
                              ) : (
                                <>
                                  <button
                                    onClick={() => handleOpenPhoto(order)}
                                    className="text-indigo-600 hover:text-indigo-900"
                                  >
                                    Photo
                                  </button>
                                  <button
                                    onClick={() => handleEditOrder(order)}
                                    className="text-indigo-600 hover:text-indigo-900 ml-4"
                                  >
                                    Edit
                                  </button>
                                  <button
                                    onClick={() => handleDeleteRow(order.id)}
                                    className="text-red-600 hover:text-red-900 ml-4"
                                  >
                                    Delete
                                  </button>
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td
                          colSpan={11}
                          className="text-primary-2 font-bold text-xl text-center py-2"
                        >
                          ઓર્ડર ની વિગતો મળી નથી
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrderList;
