import React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

import styles from "./PhotoModel.module.scss";
import {
  getIsOrderPhotoModalOpen,
  toggleOrderPhotoModal,
} from "../../features/orders/ordersSlice";
import { GiCancel } from "react-icons/gi";

const PhotoModel = () => {
  const dispatch = useAppDispatch();
  const orderPhoto = useAppSelector(getIsOrderPhotoModalOpen);

  const handleCloseModel = () => {
    dispatch(toggleOrderPhotoModal(null));
  };

  return (
    <div className={styles.PhotoModel}>
      <div className="relative h-[100vh] w-[100vw] overflow-y-scroll lg:overflow-auto top-0 lg:top-10 lg:mx-auto p-5 border lg:w-96 lg:h-fit shadow-lg lg:rounded-md bg-white">
        <div className="mt-3">
          <h3 className="text-2xl text-center font-bold leading-6 text-primary-1">
            ઓર્ડર નો ફોટો
          </h3>
          <GiCancel
            onClick={handleCloseModel}
            className="absolute cursor-pointer z-10 shadow bg-white top-3 right-5 text-3xl lg:text-xl text-primary-1 border-2 border-primary-1 p-1 lg:p-0 rounded-full"
          />

          <div className="mt-2 py-3 h-full w-full">
            {orderPhoto !== null && (
              <img className="object-fill" src={orderPhoto} alt="" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhotoModel;
