import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET } from "../../services/axios.service";
import { RootState } from "../../app/store";
import { ItemsProps } from "../../types/item";

export interface ItemsState {
  items: ItemsProps[];
  totalItems: number;
  isItemModalOpen: boolean;
  editItem: ItemsProps | null;
  loading: boolean;
  error: string | null;
}

const initialState: ItemsState = {
  items: [],
  totalItems: 0,
  isItemModalOpen: false,
  editItem: null,
  loading: false,
  error: null,
};

type FetchItemsListDataProps = {
  page: number;
  size?: number;
  fromDate?: string | null;
  toDate?: string | null;
  search?: string | null;
};

export const fetchItemsListData = createAsyncThunk(
  "fetchItemsListData",
  async (req: FetchItemsListDataProps) => {
    let response: any;
    await GET(`/v1/admin/items`, { ...req })
      .then((res: any) => {
        response = res;
      })
      .catch((err) => {
        response = err;
      });
    return response;
  }
);

export const itemsSlice = createSlice({
  name: "items",
  initialState,
  reducers: {
    toggleItemModal: (state, action: PayloadAction<boolean>) => {
      state.isItemModalOpen = action.payload;
    },
    setEditItem: (state, action: PayloadAction<ItemsProps | null>) => {
      state.editItem = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchItemsListData.pending, (state, action: any) => {
      state.items = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchItemsListData.fulfilled, (state, action: any) => {
      state.loading = false;
      if (action.payload.data.status === "success") {
        state.items = action.payload.data.data.records;
        state.totalItems = action.payload.data.data.total_records;
        state.error = null;
      } else {
        state.items = [];
        state.error =
          action?.payload?.data?.message ||
          "કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.";
      }
    });
    builder.addCase(fetchItemsListData.rejected, (state, action: any) => {
      state.items = [];
      state.loading = false;
      state.error =
        action?.payload?.data?.message ||
        "કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.";
    });
  },
});

export const { toggleItemModal, setEditItem } = itemsSlice.actions;

export const getItems = (state: RootState) => state.items.items;

export const getTotalItems = (state: RootState) => state.items.totalItems;

export const getIsItemModalOpen = (state: RootState) =>
  state.items.isItemModalOpen;

export const getEditItem = (state: RootState) => state.items.editItem;

export default itemsSlice.reducer;
