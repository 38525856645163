import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET } from "../../services/axios.service";
import { RootState } from "../../app/store";
import { UsersProps } from "../../types/users";

export interface UsersState {
  users: UsersProps[];
  totalUsers: number;
  isUserModalOpen: boolean;
  editUser: UsersProps | null;
  loading: boolean;
  error: string | null;
}

const initialState: UsersState = {
  users: [],
  totalUsers: 0,
  isUserModalOpen: false,
  editUser: null,
  loading: false,
  error: null,
};

type FetchUsersListDataProps = {
  page: number;
  size?: number;
};

export const fetchUsersListData = createAsyncThunk(
  "fetchUsersListData",
  async (req: FetchUsersListDataProps) => {
    let response: any;
    await GET(`/v1/admin/users`, { ...req })
      .then((res: any) => {
        response = res;
      })
      .catch((err) => {
        response = err;
      });
    return response;
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    toggleUserModal: (state, action: PayloadAction<boolean>) => {
      state.isUserModalOpen = action.payload;
    },
    setEditUser: (state, action: PayloadAction<UsersProps | null>) => {
      state.editUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsersListData.pending, (state, action: any) => {
      state.users = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchUsersListData.fulfilled, (state, action: any) => {
      state.loading = false;
      if (action.payload.data.status === "success") {
        state.users = action.payload.data.data.records;
        state.totalUsers = action.payload.data.data.total_records;
        state.error = null;
      } else {
        state.users = [];
        state.error =
          action?.payload?.data?.message ||
          "કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.";
      }
    });
    builder.addCase(fetchUsersListData.rejected, (state, action: any) => {
      state.users = [];
      state.loading = false;
      state.error =
        action?.payload?.data?.message ||
        "કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.";
    });
  },
});

export const { toggleUserModal, setEditUser } = usersSlice.actions;

export const getUsers = (state: RootState) => state.users.users;

export const getTotalUsers = (state: RootState) => state.users.totalUsers;

export const getIsUserModalOpen = (state: RootState) =>
  state.users.isUserModalOpen;

export const getEditUser = (state: RootState) => state.users.editUser;

export default usersSlice.reducer;
