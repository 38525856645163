import React, { useEffect, useState } from "react";

import styles from "./CreateForm.module.scss";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { GiCancel } from "react-icons/gi";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { IFormInput, OptionsProps } from "../../types/salaries";
import Select from "react-select";
import { selectStyles, toastStyles } from "../../static/styles";
import { GET } from "../../services/axios.service";
import moment from "moment";
import { FaCalendarAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import {
  getEmployee,
  toggleCreateModal,
} from "../../features/salaries/salariesSlice";

type Props = {
  handleCreateSalaries: (data: IFormInput) => void;
};

const CreateForm = ({ handleCreateSalaries }: Props) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const employee = useAppSelector(getEmployee);

  const [isStep2Open, setIsStep2Open] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSalaries, setIsLoadingSalaries] = useState<boolean>(false);
  const [pendingSalaries, setPendingSalaries] = useState<
    { from_date: string; to_date: string }[]
  >([]);
  const [orderAmount, setOrderAmount] = useState<number>(0);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [totalHours, setTotalHours] = useState<number>(0);
  const [totalWithdrawal, setTotalWithdrawal] = useState<number>(0);
  const [lastTotalWithdrawal, setLastTotalWithdrawal] = useState<number>(0);
  const [lastTotalAmount, setLastTotalAmount] = useState<number>(0);

  const {
    handleSubmit,
    register,
    reset,
    control,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<IFormInput>();

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    if (id) {
      setIsLoading(true);
      await handleCreateSalaries({
        ...data,
        employee_id: parseInt(id),
        status: 0,
      });
      setIsLoading(false);
      handleCloseModal();
    }
  };

  const handleCloseModal = () => {
    reset();
    dispatch(toggleCreateModal(0));
  };

  const handleChangeEmployee = async (id: string) => {
    setIsLoadingSalaries(true);

    await GET(`/v1/admin/salaries/employees/${id}/pending-salary`, {})
      .then(async (response: any) => {
        if (response.data.status === "success") {
          setPendingSalaries(response.data.data || []);
        } else {
          setPendingSalaries([]);
        }
      })
      .catch((error: any) => {
        setPendingSalaries([]);
      });

    setIsLoadingSalaries(false);
  };

  const handleGoToNextStep = async () => {
    setIsLoading(true);

    setValue("roundoff_amount", 0);
    setValue("withdrawal_deposit", 0);

    const isFromValid = await trigger("fromDate");
    const isToValid = await trigger("toDate");

    if (id && isFromValid && isToValid) {
      const data = getValues();

      try {
        await GET(`/v1/admin/salaries/employees/${id}/amount`, {
          fromDate: data.fromDate,
          toDate: data.toDate,
        })
          .then(async (response: any) => {
            if (response.data.status === "success") {
              setOrderAmount(response.data.data.total_amount);
              setTotalAmount(response.data.data.total_amount);
              setTotalHours(response.data.data.total_hours);
              setTotalWithdrawal(response.data.data.total_withdrawal);
              setLastTotalWithdrawal(response.data.data.total_withdrawal);
              setLastTotalAmount(response.data.data.total_amount);
              setIsStep2Open(true);
            } else {
              toast.error(response.data.message, {
                ...(toastStyles as any),
              });
            }
          })
          .catch((error: any) => {
            toast.error(error.data.message, {
              ...(toastStyles as any),
            });
          });
      } catch (error) {
        toast.error("કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.", {
          ...(toastStyles as any),
        });
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    handleChangeEmployee(id || "0");
  }, [id]);

  return (
    <div className={styles.CreateForm}>
      <div className="relative h-[100vh] w-[100vw] overflow-y-scroll lg:overflow-auto top-0 lg:top-10 lg:mx-auto p-5 border lg:w-[80vw] lg:h-fit lg:min-h-[calc(100vh-80px)] shadow-lg lg:rounded-md bg-white">
        <div className="mt-3">
          <h3 className="text-2xl mt-10 lg:mt-0 text-center lg:text-left font-bold leading-6 text-primary-1">
            હિસાબ ની માહિતી
          </h3>

          <GiCancel
            onClick={handleCloseModal}
            className="absolute cursor-pointer z-10 shadow bg-white top-3 right-5 text-3xl lg:text-xl text-primary-1 border-2 border-primary-1 p-1 lg:p-0 rounded-full"
          />
          <div className="mt-2 py-3 lg:py-2">
            <form onSubmit={handleSubmit(onSubmit)} className="p-4 pt-1 pb-0">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        colSpan={2}
                        className="px-6 py-3 text-center text-base text-primary-1 font-bold uppercase tracking-wider"
                      >
                        હિસાબ બાકી હોય એવી હાજરી ની વિગતો.
                      </th>
                    </tr>
                    <tr>
                      <th className="px-6 py-3 text-center text-base text-primary-1 font-bold uppercase tracking-wider">
                        તારીખ થી
                      </th>
                      <th className="px-6 py-3 text-center text-base text-primary-1 font-bold uppercase tracking-wider">
                        તારીખ સુધી
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {isLoadingSalaries ? (
                      <tr>
                        <td
                          colSpan={2}
                          className="text-primary-2 font-bold text-xl text-center py-2"
                        >
                          રાહ જુઓ...
                        </td>
                      </tr>
                    ) : (
                      <>
                        {pendingSalaries.length > 0 ? (
                          <>
                            {pendingSalaries.map((salaries, index) => (
                              <tr key={index}>
                                <td className="text-center px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                  {moment(salaries.from_date).format(
                                    "DD-MM-YYYY"
                                  )}
                                </td>
                                <td className="text-center px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                  {moment(salaries.to_date).format(
                                    "DD-MM-YYYY"
                                  )}
                                </td>
                              </tr>
                            ))}
                          </>
                        ) : (
                          <tr>
                            <td
                              colSpan={2}
                              className="text-primary-2 font-bold text-xl lg:text-lg text-center py-2"
                            >
                              બાકી હિસાબ ની વિગતો મળી નથી
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6">
                <div>
                  <label className="text-primary-1 font-bold">તારીખ થી :</label>
                  <div className="flex items-center bg-gradient-to-r from-primary-1 to-primary-2 rounded pr-[2px] h-[44px]">
                    <FaCalendarAlt className="text-white mx-2" />
                    <input
                      className="bg-white text-primary-1 placeholder:text-primary-2 font-semibold text-xl focus:outline-none p-2 rounded flex-grow h-[40px]"
                      type="date"
                      {...register("fromDate", {
                        required: true,
                      })}
                      onChange={() => {
                        setIsStep2Open(false);
                        return true;
                      }}
                      placeholder="From Date"
                    />
                  </div>

                  {errors?.fromDate && (
                    <span className="text-red-500">તારીખ સિલેક્ટ કરો</span>
                  )}
                </div>

                <div>
                  <label className="text-primary-1 font-bold">
                    તારીખ સુધી :
                  </label>
                  <div className="flex items-center bg-gradient-to-r from-primary-1 to-primary-2 rounded pr-[2px] h-[44px]">
                    <FaCalendarAlt className="text-white mx-2" />
                    <input
                      className="bg-white text-primary-1  placeholder:text-primary-2 font-semibold text-xl focus:outline-none p-2 rounded flex-grow h-[40px]"
                      type="date"
                      {...register("toDate", {
                        required: true,
                      })}
                      onChange={() => {
                        setIsStep2Open(false);
                        return true;
                      }}
                      placeholder="To Date"
                    />
                  </div>

                  {errors?.toDate && (
                    <span className="text-red-500">તારીખ સિલેક્ટ કરો</span>
                  )}
                </div>
              </div>

              {isStep2Open === false ? (
                <div className="mt-4 text-center">
                  <button
                    className="mx-auto shadow disabled:opacity-50 rounded-3xl text-3xl lg:text-xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
                    type="button"
                    disabled={isLoading}
                    onClick={handleGoToNextStep}
                  >
                    {isLoading ? "રાહ જુઓ..." : "આગળ વધો"}
                  </button>
                </div>
              ) : (
                <>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
                    <div className="mt-1">
                      <label className="text-primary-1 font-bold">
                        હિસાબ કલાકો * Rs. :
                      </label>
                      <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-11 w-full relative rounded">
                        <input
                          value={`${totalHours || 0} * ${employee.amount || 0}`}
                          className="text-primary-1 bg-gray-300 focus:outline-none px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                          type="text"
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="mt-1">
                      <label className="text-primary-1 font-bold">
                        હિસાબ અમાઉન્ટ :
                      </label>
                      <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-11 w-full relative rounded">
                        <input
                          value={orderAmount || 0}
                          className="text-primary-1 bg-gray-300 focus:outline-none px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                          type="text"
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="mt-1">
                      <label className="text-primary-1 font-bold">
                        રાઉન્ડ ઑફ :
                      </label>
                      <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-11 w-full relative rounded">
                        <input
                          className="text-primary-1 focus:outline-none px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                          type="number"
                          pattern="-?[0-9]*"
                          step={"any"}
                          placeholder="રાઉન્ડ ઑફ દાખલ કરો"
                          {...register("roundoff_amount", { required: false })}
                          onFocus={(e) =>
                            e.target.addEventListener(
                              "wheel",
                              function (e) {
                                e.preventDefault();
                              },
                              { passive: false }
                            )
                          }
                          onKeyUp={(e: any) => {
                            const roundoff_amount = e.target.value || 0;
                            const { withdrawal_deposit } = getValues();

                            const total =
                              parseFloat(`${orderAmount || 0}`) +
                              parseFloat(`${roundoff_amount || 0}`);
                            setTotalAmount(total);

                            const total1 =
                              parseFloat(`${totalWithdrawal || 0}`) -
                              parseFloat(`${withdrawal_deposit || 0}`);
                            setLastTotalWithdrawal(total1);

                            const total2 =
                              parseFloat(`${total || 0}`) -
                              parseFloat(`${withdrawal_deposit || 0}`);
                            setLastTotalAmount(total2);

                            return true;
                          }}
                        />
                      </div>
                      {errors.roundoff_amount &&
                        errors.roundoff_amount.type === "required" && (
                          <span className="text-red-500">
                            રાઉન્ડ ઑફ દાખલ કરો
                          </span>
                        )}
                    </div>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
                    <div className="mt-1">
                      <label className="text-primary-1 font-bold">
                        હિસાબ ટોટલ અમાઉન્ટ :
                      </label>
                      <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-11 w-full relative rounded">
                        <input
                          value={`${(totalAmount || 0)?.toFixed(2)}`}
                          className="text-primary-1 bg-gray-300 focus:outline-none px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                          type="text"
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="mt-1">
                      <label className="text-primary-1 font-bold">
                        ટોટલ ઉપાડ :
                      </label>
                      <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-11 w-full relative rounded">
                        <input
                          value={`${(totalWithdrawal || 0)?.toFixed(2)}`}
                          className="text-primary-1 bg-gray-300 focus:outline-none px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                          type="text"
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="mt-1">
                      <label className="text-primary-1 font-bold">
                        કપાત ઉપાડ :
                      </label>
                      <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-11 w-full relative rounded">
                        <input
                          className="text-primary-1 focus:outline-none px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                          type="number"
                          pattern="-?[0-9]*"
                          step={"any"}
                          placeholder="કપાત ઉપાડ દાખલ કરો"
                          {...register("withdrawal_deposit", {
                            required: false,
                          })}
                          onFocus={(e) =>
                            e.target.addEventListener(
                              "wheel",
                              function (e) {
                                e.preventDefault();
                              },
                              { passive: false }
                            )
                          }
                          onKeyUp={(e: any) => {
                            const withdrawal_deposit = e.target.value || 0;
                            const { roundoff_amount } = getValues();

                            const total =
                              parseFloat(`${orderAmount || 0}`) +
                              parseFloat(`${roundoff_amount || 0}`);
                            setTotalAmount(total);

                            const total1 =
                              parseFloat(`${totalWithdrawal || 0}`) -
                              parseFloat(`${withdrawal_deposit || 0}`);
                            setLastTotalWithdrawal(total1);

                            const total2 =
                              parseFloat(`${total || 0}`) -
                              parseFloat(`${withdrawal_deposit || 0}`);
                            setLastTotalAmount(total2);

                            return true;
                          }}
                        />
                      </div>
                      {errors.withdrawal_deposit &&
                        errors.withdrawal_deposit.type === "required" && (
                          <span className="text-red-500">
                            કપાત ઉપાડ દાખલ કરો
                          </span>
                        )}
                    </div>
                  </div>

                  <h3 className="text-2xl mt-6 text-left font-bold leading-8 text-primary-1">
                    બાકી ઉપાડ અમાઉન્ટ - Rs.{" "}
                    {lastTotalWithdrawal
                      ? lastTotalWithdrawal?.toFixed(2)
                      : "0.00"}
                  </h3>

                  <h3 className="text-2xl mt-6 text-left font-bold leading-8 text-primary-1">
                    પગાર અમાઉન્ટ - Rs.{" "}
                    {lastTotalAmount ? lastTotalAmount?.toFixed(2) : "0.00"}
                  </h3>

                  <div className="mt-4 text-center">
                    <button
                      className="mx-auto shadow disabled:opacity-50 rounded-3xl text-3xl lg:text-xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? "રાહ જુઓ..." : "આગળ વધો"}
                    </button>
                  </div>
                </>
              )}

              <div className="mt-4 text-center">
                <button
                  className="mx-auto shadow disabled:opacity-50 rounded-3xl text-3xl lg:text-xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
                  type="button"
                  onClick={handleCloseModal}
                  disabled={isLoading}
                >
                  બંધ કરો
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateForm;
