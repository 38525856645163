import React, { useEffect, useState } from "react";
import Layout from "../../components/layout";
import { FaArrowLeft, FaPlus, FaSearch, FaCalendarAlt } from "react-icons/fa";

import styles from "./ProcessesPage.module.scss";
import { useNavigate } from "react-router-dom";
import { route } from "../../static/route";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  fetchProcessesListData,
  getEditProcess,
  getProcesses,
  getTotalProcesses,
  setEditProcess,
  toggleProcessModal,
} from "../../features/processes/processesSlice";
import ProcessList from "../../components/processes/ProcessList";
import Pagination from "../../components/pagination";
import ProcessFormModal from "../../components/processes/ProcessFormModal";
import { IFormInput } from "../../types/process";
import { toast } from "react-toastify";
import { toastStyles } from "../../static/styles";
import { DELETE, POST, PUT } from "../../services/axios.service";
import { useForm } from "react-hook-form";

const ProcessesPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const processes = useAppSelector(getProcesses);
  const totalProcesses = useAppSelector(getTotalProcesses);
  const editProcess = useAppSelector(getEditProcess);

  interface FilterFormInput {
    name: string;
    fromDate: string;
    toDate: string;
  }

  const { handleSubmit, register, reset, getValues } =
    useForm<FilterFormInput>();

  const [page, setPage] = useState<number>(1);
  const [pageSize] = useState<number>(50);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState(false);

  const hanldeGoToHome = () => {
    navigate(route.dashboard);
  };

  const paginate = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const handleShowProcessForm = async () => {
    await dispatch(setEditProcess(null));
    dispatch(toggleProcessModal(true));
  };

  const handleSaveProcess = async (data: IFormInput) => {
    try {
      if (editProcess && editProcess.id) {
        await PUT(`/v1/admin/processes/${editProcess.id}`, {
          name: data.name,
          description: data.description,
        })
          .then(async (response: any) => {
            if (response.data.status === "success") {
              await dispatch(setEditProcess(null));

              if (page === 1) {
                fetchProcessesList(page, pageSize);
              } else {
                setPage(1);
              }

              toast.success(response.data.message, {
                ...(toastStyles as any),
              });
            } else {
              toast.error(response.data.message, {
                ...(toastStyles as any),
              });
            }
          })
          .catch((error: any) => {
            toast.error(error.data.message, {
              ...(toastStyles as any),
            });
          });
      } else {
        await POST("/v1/admin/processes", {
          name: data.name,
          description: data.description,
        })
          .then(async (response: any) => {
            if (response.data.status === "success") {
              if (page === 1) {
                fetchProcessesList(page, pageSize);
              } else {
                setPage(1);
              }

              toast.success(response.data.message, {
                ...(toastStyles as any),
              });
            } else {
              toast.error(response.data.message, {
                ...(toastStyles as any),
              });
            }
          })
          .catch((error: any) => {
            toast.error(error.data.message, {
              ...(toastStyles as any),
            });
          });
      }
    } catch (error) {
      toast.error("કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.", {
        ...(toastStyles as any),
      });
    }
  };

  const handleDeleteProcess = async (id: number) => {
    try {
      await DELETE(`/v1/admin/processes/${id}`)
        .then(async (response: any) => {
          if (response.data.status === "success") {
            const pageNumber = processes.length > 1 ? page : page - 1;
            if (page === pageNumber) {
              fetchProcessesList(page, pageSize);
            } else {
              setPage(pageNumber);
            }

            toast.success(response.data.message, {
              ...(toastStyles as any),
            });
          } else {
            toast.error(response.data.message, {
              ...(toastStyles as any),
            });
          }
        })
        .catch((error: any) => {
          toast.error(error.data.message, {
            ...(toastStyles as any),
          });
        });
    } catch (error) {
      toast.error("કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.", {
        ...(toastStyles as any),
      });
    }
  };

  const handleOnFilterSubmit = async () => {
    fetchProcessesList(page, pageSize);
  };

  const handleClearFilter = () => {
    reset();
    fetchProcessesList(page, pageSize);
  };

  const fetchProcessesList = async (page: number, size: number) => {
    const data = getValues();

    setIsLoading(true);

    dispatch(
      fetchProcessesListData({
        page: page,
        size: size,
        search: data?.name || null,
        fromDate: data?.fromDate || null,
        toDate: data?.toDate || null,
      })
    ).then(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetchProcessesList(page, pageSize);
  }, [page]);

  return (
    <Layout>
      <div className={styles.ProcessesPage}>
        <div className="logoSection">
          <h1
            onClick={hanldeGoToHome}
            className="text-5xl font-bold text-white lg:text-3xl cursor-pointer lg:w-fit"
          >
            કનૈયા પ્લેટિંગ
          </h1>

          <hr className="w-[180px] border-white border-[4px] rounded-[4px] mx-auto mt-4 lg:mx-0 lg:w-24 lg:border-[2px] lg:mt-2" />
        </div>

        <div className="pageTitleSection">
          <FaArrowLeft
            onClick={hanldeGoToHome}
            className="lg:hidden absolute top-5 left-5 text-3xl text-white border-2 border-white p-1 rounded-full"
          />

          <h1 className="text-5xl mt-6 lg:mt-0 font-bold text-white lg:text-2xl lg:text-primary-1">
            પ્રોસેસ ની વિગતો
          </h1>
        </div>

        <div className="mt-[60px] px-10 py-5">
          <div className="flex justify-between lg:justify-end gap-4 items-center mb-4">
            <button
              className="p-2 rounded-md flex items-center bg-gradient-to-r from-primary-1 to-primary-2 text-white transition-colors duration-300"
              onClick={() => setShowFilter(!showFilter)}
            >
              <FaSearch className="mr-2" />{" "}
              {showFilter ? "ફિલ્ટર છુપાવો" : "ફિલ્ટર બતાવો"}
            </button>
            <button
              className="bg-gradient-to-r from-primary-1 to-primary-2 text-white p-2 rounded-md flex items-center transition-colors duration-300"
              onClick={handleShowProcessForm}
            >
              <FaPlus className="mr-2" /> નવી આઇટમ ઉમેરો
            </button>
          </div>

          {showFilter && (
            <form onSubmit={handleSubmit(handleOnFilterSubmit)}>
              <div className="mb-4 bg-white shadow rounded p-4">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div className="flex items-center bg-gradient-to-r from-primary-1 to-primary-2 rounded pr-[2px] h-[44px]">
                    <FaCalendarAlt className="text-white mx-2" />
                    <input
                      className="bg-white text-primary-1 placeholder:text-primary-2 font-semibold text-xl focus:outline-none p-2 rounded flex-grow h-[40px]"
                      type="date"
                      {...register("fromDate")}
                      placeholder="From Date"
                    />
                  </div>

                  <div className="flex items-center bg-gradient-to-r from-primary-1 to-primary-2 rounded pr-[2px] h-[44px]">
                    <FaCalendarAlt className="text-white mx-2" />
                    <input
                      className="bg-white text-primary-1  placeholder:text-primary-2 font-semibold text-xl focus:outline-none p-2 rounded flex-grow h-[40px]"
                      type="date"
                      {...register("toDate")}
                      placeholder="To Date"
                    />
                  </div>

                  <div className="flex items-center bg-gradient-to-r from-primary-1 to-primary-2 rounded p-[2px] h-[44px]">
                    <input
                      {...register("name")}
                      className="text-primary-1 px-3 py-1 font-semibold text-xl placeholder:text-primary-2 w-full h-full rounded focus:outline-none"
                      placeholder="નામ દ્વારા શોધો"
                    />
                  </div>
                </div>
                <div className="flex gap-4 justify-end mt-4">
                  <button
                    onClick={handleClearFilter}
                    type="button"
                    className="bg-primary-1 hover:bg-primary-2 text-white px-4 py-2 rounded-md transition duration-300 ease-in-out"
                  >
                    હટાઓ
                  </button>

                  <button
                    type="submit"
                    className="bg-primary-1 hover:bg-primary-2 text-white px-4 py-2 rounded-md transition duration-300 ease-in-out"
                  >
                    ફિલ્ટર લાગુ કરો
                  </button>
                </div>
              </div>
            </form>
          )}

          <ProcessList
            processes={processes}
            pageNumber={page}
            pageSize={pageSize}
            isLoading={isLoading}
            handleDeleteProcess={handleDeleteProcess}
          />

          <Pagination
            itemsPerPage={pageSize}
            totalItems={totalProcesses}
            paginate={paginate}
            currentPage={page}
          />
        </div>

        <ProcessFormModal handleSaveProcess={handleSaveProcess} />
      </div>
    </Layout>
  );
};
export default ProcessesPage;
