import React, { useState } from "react";
import styles from "./ProcessList.module.scss";

import moment from "moment";
import { ProcessesProps } from "../../types/process";
import { useAppDispatch } from "../../app/hooks";
import {
  setEditProcess,
  toggleProcessModal,
} from "../../features/processes/processesSlice";
import useConfirmPopup from "../../context/useConfirmPopup";

type Props = {
  processes: ProcessesProps[];
  pageNumber: number;
  pageSize: number;
  isLoading: boolean;
  handleDeleteProcess: (id: number) => void;
};

const ProcessList = ({
  processes,
  pageNumber,
  pageSize,
  isLoading,
  handleDeleteProcess,
}: Props) => {
  const dispatch = useAppDispatch();
  const confirmDelete = useConfirmPopup();

  const [isActionLoading, setIsActionLoading] = useState<number>(0);

  const handleEditProcess = async (process: ProcessesProps) => {
    await dispatch(setEditProcess(process));
    dispatch(toggleProcessModal(true));
  };

  const handleDeleteRow = async (id: number) => {
    const confirmed = await confirmDelete(
      `તમે આને પાછું ફેરવી શકશો નહીં!`,
      `શું તમે ખાતરી આપો છો?`,
      "હા, તેને કાઢી નાખો!"
    );

    if (confirmed) {
      setIsActionLoading(id);
      await handleDeleteProcess(id);
      setIsActionLoading(0);
    }
  };

  return (
    <div className={styles.ProcessList}>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    Sr. No.
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    પ્રોસેસ નામ
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    તારીખ
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-right text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    એકશન્સ બટન
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {isLoading ? (
                  <tr>
                    <td
                      colSpan={4}
                      className="text-primary-2 font-bold text-xl text-center py-2"
                    >
                      રાહ જુઓ...
                    </td>
                  </tr>
                ) : (
                  <>
                    {processes.length > 0 ? (
                      <>
                        {processes.map((process, index) => (
                          <tr key={index}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {(pageNumber - 1) * pageSize + index + 1}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {process.name || "-"}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {moment(process.created_at).format(
                                "DD-MM-YYYY hh:mm A"
                              )}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                              {isActionLoading === process.id ? (
                                <p className="text-primary-2 font-bold text-right text-lg">
                                  રાહ જુઓ...
                                </p>
                              ) : (
                                <>
                                  <button
                                    onClick={() => handleEditProcess(process)}
                                    className="text-indigo-600 hover:text-indigo-900"
                                  >
                                    Edit
                                  </button>
                                  <button
                                    onClick={() => handleDeleteRow(process.id)}
                                    className="text-red-600 hover:text-red-900 ml-4"
                                  >
                                    Delete
                                  </button>
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td
                          colSpan={4}
                          className="text-primary-2 font-bold text-xl text-center py-2"
                        >
                          પ્રોસેસ ની વિગતો મળી નથી
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProcessList;
