import React, { useEffect, useState } from "react";
import Layout from "../../components/layout";

import styles from "./LoginPage.module.scss";
import { SubmitHandler, useForm } from "react-hook-form";
import { POST } from "../../services/axios.service";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { route } from "../../static/route";

const LoginPage = () => {
  const navigate = useNavigate();
  const { isAuthenticated, setIsAuthenticated } = useAuth();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [requestError, setRequestError] = useState<string | null>(null);

  interface IFormInput {
    phone: string;
    password: string;
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<IFormInput>();

  const handleOnSubmit: SubmitHandler<IFormInput> = async (data: any) => {
    setIsLoading(true);
    setRequestError(null);

    try {
      await POST("/v1/admin/login", {
        phone: data.phone,
        password: data.password,
      })
        .then(async (response: any) => {
          if (response.data.status === "success") {
            await localStorage.setItem("authToken", response.data.data.token);
            await setIsAuthenticated(true);

            navigate(route.dashboard);
          } else {
            setRequestError(response.data.message);
          }
        })
        .catch((error: any) => {
          setRequestError(error.data.message);
        });
    } catch (error) {
      setRequestError("કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.");
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (isAuthenticated) navigate(route.dashboard);
  }, [isAuthenticated]);

  return (
    <Layout>
      <div className={styles.LoginPage}>
        <div className="logoSection">
          <h1 className="text-5xl font-bold text-white lg:text-3xl">
            કનૈયા પ્લેટિંગ
          </h1>

          <hr className="w-[180px] border-white border-[4px] rounded-[4px] mx-auto mt-4 lg:mx-0 lg:w-24 lg:border-[2px] lg:mt-2" />
        </div>

        <div className="formSection">
          <form
            onSubmit={handleSubmit(handleOnSubmit)}
            className="flex flex-col"
          >
            <h1 className="text-primary-1 text-5xl font-bold">Login</h1>

            <p className="mt-4 text-primary-2 font-semibold text-lg">
              એડમીન માટે અહી થી Login કરો.
            </p>

            <div className="mt-6">
              <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 w-full relative rounded">
                <input
                  className="text-primary-1 px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                  type="text"
                  placeholder="મોબાઇલ નંબર દાખલ કરો"
                  {...register("phone", { required: true })}
                />
              </div>
              {errors.phone && errors.phone.type === "required" && (
                <span className="text-red-500">મોબાઇલ નંબર દાખલ કરો</span>
              )}
            </div>

            <div className="mt-6">
              <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 w-full relative rounded">
                <input
                  className="text-primary-1 px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                  type="password"
                  placeholder="પાસવર્ડ દાખલ કરો"
                  {...register("password", { required: true })}
                />
              </div>
              {errors.password && errors.password.type === "required" && (
                <span className="text-red-500">પાસવર્ડ દાખલ કરો</span>
              )}
            </div>

            {requestError !== null && (
              <span className="text-red-500 text-center mt-8">
                {requestError}
              </span>
            )}

            <button
              className="mx-auto mt-6 shadow disabled:opacity-50 rounded-3xl text-3xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? "રાહ જુઓ..." : "આગળ વધો"}
            </button>
          </form>
        </div>
      </div>
    </Layout>
  );
};
export default LoginPage;
