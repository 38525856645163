import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET } from "../../services/axios.service";
import { RootState } from "../../app/store";
import { OrdersProps, PartyItemsProps } from "../../types/orders";
import { ItemsProps } from "../../types/item";
import { ProcessesProps } from "../../types/process";
import { PartiesProps } from "../../types/parties";

export interface OrdersState {
  orders: OrdersProps[];
  totalOrders: number;
  totalAmount: number;
  isOrderModalOpen: boolean;
  isOrderPhotoModalOpen: string | null;
  editOrder: OrdersProps | null;
  editPartyItems: PartyItemsProps[];
  items: ItemsProps[];
  processes: ProcessesProps[];
  parties: PartiesProps[];
  loading: boolean;
  error: string | null;
}

const initialState: OrdersState = {
  orders: [],
  totalOrders: 0,
  totalAmount: 0,
  isOrderModalOpen: false,
  isOrderPhotoModalOpen: null,
  editOrder: null,
  editPartyItems: [],
  items: [],
  processes: [],
  parties: [],
  loading: false,
  error: null,
};

type FetchOrdersListDataProps = {
  page: number;
  size?: number;
  fromDate?: string | null;
  toDate?: string | null;
  search?: string | null;
  party_id?: number | null;
  item_id?: number | null;
  process_id?: number | null;
  status?: number | null;
};

export const fetchOrdersListData = createAsyncThunk(
  "fetchOrdersListData",
  async (req: FetchOrdersListDataProps) => {
    let response: any;
    await GET(`/v1/admin/orders`, { ...req })
      .then((res: any) => {
        response = res;
      })
      .catch((err) => {
        response = err;
      });
    return response;
  }
);

export const fetchItemsList = createAsyncThunk(
  "fetchItemsListForOrderFilter",
  async () => {
    let response: any;
    await GET(`/v1/admin/items`, { all: true })
      .then((res: any) => {
        response = res;
      })
      .catch((err) => {
        response = err;
      });
    return response;
  }
);

export const fetchProcessesList = createAsyncThunk(
  "fetchProcessesListForOrderFilter",
  async () => {
    let response: any;
    await GET(`/v1/admin/processes`, { all: true })
      .then((res: any) => {
        response = res;
      })
      .catch((err) => {
        response = err;
      });
    return response;
  }
);

export const fetchPartiesList = createAsyncThunk(
  "fetchPartiesListForOrderFilter",
  async () => {
    let response: any;
    await GET(`/v1/admin/parties`, { all: true })
      .then((res: any) => {
        response = res;
      })
      .catch((err) => {
        response = err;
      });
    return response;
  }
);

export const fetchEditPartyItemsList = createAsyncThunk(
  "fetchEditPartyItemsList",
  async (id: number) => {
    let response: any;
    await GET(`/v1/admin/orders/parties/${id}/items`, {})
      .then((res: any) => {
        response = res;
      })
      .catch((err) => {
        response = err;
      });
    return response;
  }
);

export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    toggleOrderModal: (state, action: PayloadAction<boolean>) => {
      state.isOrderModalOpen = action.payload;
    },
    toggleOrderPhotoModal: (state, action: PayloadAction<string | null>) => {
      state.isOrderPhotoModalOpen = action.payload;
    },
    setEditOrder: (state, action: PayloadAction<OrdersProps | null>) => {
      state.editOrder = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrdersListData.pending, (state, action: any) => {
      state.orders = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchOrdersListData.fulfilled, (state, action: any) => {
      state.loading = false;
      if (action.payload.data.status === "success") {
        state.orders = action.payload.data.data.records;
        state.totalOrders = action.payload.data.data.total_records;
        state.totalAmount = action.payload.data.data.total_amount;
        state.error = null;
      } else {
        state.orders = [];
        state.totalAmount = 0;
        state.error =
          action?.payload?.data?.message ||
          "કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.";
      }
    });
    builder.addCase(fetchOrdersListData.rejected, (state, action: any) => {
      state.orders = [];
      state.loading = false;
      state.error =
        action?.payload?.data?.message ||
        "કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.";
    });
    builder.addCase(fetchItemsList.pending, (state, action: any) => {
      state.items = [];
    });
    builder.addCase(fetchItemsList.fulfilled, (state, action: any) => {
      if (action.payload.data.status === "success") {
        state.items = action.payload.data.data.records;
      } else {
        state.items = [];
      }
    });
    builder.addCase(fetchItemsList.rejected, (state, action: any) => {
      state.items = [];
    });
    builder.addCase(fetchProcessesList.pending, (state, action: any) => {
      state.processes = [];
    });
    builder.addCase(fetchProcessesList.fulfilled, (state, action: any) => {
      if (action.payload.data.status === "success") {
        state.processes = action.payload.data.data.records;
      } else {
        state.processes = [];
      }
    });
    builder.addCase(fetchProcessesList.rejected, (state, action: any) => {
      state.processes = [];
    });
    builder.addCase(fetchPartiesList.pending, (state, action: any) => {
      state.parties = [];
    });
    builder.addCase(fetchPartiesList.fulfilled, (state, action: any) => {
      if (action.payload.data.status === "success") {
        state.parties = action.payload.data.data.records;
      } else {
        state.parties = [];
      }
    });
    builder.addCase(fetchPartiesList.rejected, (state, action: any) => {
      state.parties = [];
    });
    builder.addCase(fetchEditPartyItemsList.pending, (state, action: any) => {
      state.editPartyItems = [];
    });
    builder.addCase(fetchEditPartyItemsList.fulfilled, (state, action: any) => {
      if (action.payload.data.status === "success") {
        state.editPartyItems = action.payload.data.data;
      } else {
        state.editPartyItems = [];
      }
    });
    builder.addCase(fetchEditPartyItemsList.rejected, (state, action: any) => {
      state.editPartyItems = [];
    });
  },
});

export const { toggleOrderModal, setEditOrder, toggleOrderPhotoModal } =
  ordersSlice.actions;

export const getOrders = (state: RootState) => state.orders.orders;

export const getTotalOrders = (state: RootState) => state.orders.totalOrders;

export const getTotalAmount = (state: RootState) => state.orders.totalAmount;

export const getIsOrderModalOpen = (state: RootState) =>
  state.orders.isOrderModalOpen;

export const getIsOrderPhotoModalOpen = (state: RootState) =>
  state.orders.isOrderPhotoModalOpen;

export const getEditOrder = (state: RootState) => state.orders.editOrder;

export const getEditPartyItems = (state: RootState) =>
  state.orders.editPartyItems;

export const getItems = (state: RootState) => state.orders.items;

export const getProcesses = (state: RootState) => state.orders.processes;

export const getParties = (state: RootState) => state.orders.parties;

export default ordersSlice.reducer;
