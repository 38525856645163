import React, { useState } from "react";
import styles from "./PaymentList.module.scss";

import moment from "moment";
import useConfirmPopup from "../../context/useConfirmPopup";
import { PaymentsProps } from "../../types/payments";
import { useAppDispatch } from "../../app/hooks";
import {
  setEditPayment,
  toggleCreateModal,
} from "../../features/payments/paymentsSlice";
import { GET } from "../../services/axios.service";
import { toast } from "react-toastify";
import { toastStyles } from "../../static/styles";

type Props = {
  payments: PaymentsProps[];
  pageNumber: number;
  pageSize: number;
  isLoading: boolean;
  handleDeletePayment: (id: number, password: string) => void;
};

const PaymentList = ({
  payments,
  pageNumber,
  pageSize,
  isLoading,
  handleDeletePayment,
}: Props) => {
  const dispatch = useAppDispatch();
  const confirmDelete = useConfirmPopup();

  const [isActionLoading, setIsActionLoading] = useState<number>(0);

  const handleEditPayment = async (payment: PaymentsProps) => {
    if (payment.status === 0) {
      await dispatch(setEditPayment(payment));
      dispatch(toggleCreateModal(1));
    } else if (payment.status === 1) {
      await dispatch(setEditPayment(payment));
      dispatch(toggleCreateModal(2));
    } else if (payment.status === 2) {
      await dispatch(setEditPayment(payment));
      dispatch(toggleCreateModal(3));
    }
  };

  const handleDeleteRow = async (id: number) => {
    const response = await confirmDelete(
      `તમે આને પાછું ફેરવી શકશો નહીં!`,
      `શું તમે ખાતરી આપો છો?`,
      "હા, તેને કાઢી નાખો!",
      true
    );

    if (response.confirmed) {
      setIsActionLoading(id);
      await handleDeletePayment(id, response.password);
      setIsActionLoading(0);
    }
  };

  const handlePrintReport = async (id: number) => {
    try {
      await GET(`/v1/admin/payments/${id}/reports`, {})
        .then(async (response: any) => {
          if (response.data.status === "success") {
            toast.success(response.data.message, {
              ...(toastStyles as any),
            });

            const downloadLink = response.data.data.download_link;
            try {
              const newWindow = window.open(downloadLink, "_blank");
              if (
                !newWindow ||
                newWindow.closed ||
                typeof newWindow.closed == "undefined"
              ) {
                throw new Error("Pop-up blocked");
              }
            } catch (e) {
              window.location.href = downloadLink;
            }
          } else {
            toast.error(response.data.message, {
              ...(toastStyles as any),
            });
          }
        })
        .catch((error: any) => {
          toast.error(error.data.message, {
            ...(toastStyles as any),
          });
        });
    } catch (error) {
      toast.error("કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.", {
        ...(toastStyles as any),
      });
    }
  };

  return (
    <div className={styles.PaymentList}>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    Sr. No.
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    પેમેન્ટ ID
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    ટાઈપ
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    વિગતો
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-right text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    અમાઉન્ટ
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    તારીખ
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-right text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    એકશન્સ બટન
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {isLoading ? (
                  <tr>
                    <td
                      colSpan={7}
                      className="text-primary-2 font-bold text-xl text-center py-2"
                    >
                      રાહ જુઓ...
                    </td>
                  </tr>
                ) : (
                  <>
                    {payments.length > 0 ? (
                      <>
                        {payments.map((payment, index) => (
                          <tr key={index}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {(pageNumber - 1) * pageSize + index + 1}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {payment.id || "-"}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {payment?.status === 0
                                ? "હિસાબ"
                                : payment?.status === 1
                                ? "આવેલ પેમેન્ટ"
                                : payment?.status === 2
                                ? "વધ ઘટ"
                                : ""}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {payment?.status === 0 ? (
                                <>
                                  {moment(payment.from_date).format(
                                    "DD-MM-YYYY"
                                  )}{" "}
                                  to{" "}
                                  {moment(payment.to_date).format("DD-MM-YYYY")}
                                  <br />
                                  ટેક્સ :{" "}
                                  {payment?.tax_amount?.toFixed(2) ||
                                    "0.00"}{" "}
                                  <br />
                                  રાઉન્ડ ઑફ :{" "}
                                  {payment?.roundoff_amount?.toFixed(2) ||
                                    "0.00"}
                                  <br />
                                  અગાઉ ના બાકી :{" "}
                                  {payment?.previous_due?.toFixed(2) || "0.00"}
                                </>
                              ) : payment?.status === 1 ? (
                                payment?.note || ""
                              ) : payment?.status === 2 ? (
                                <>
                                  {payment?.note || ""} -{" "}
                                  {payment?.type === 0
                                    ? "અમાઉન્ટ ઉમેરાયું"
                                    : payment?.type === 1
                                    ? "અમાઉન્ટ બાદ થયું"
                                    : ""}
                                </>
                              ) : (
                                ""
                              )}
                            </td>
                            <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-900">
                              {payment?.amount?.toFixed(2) || "0.00"}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {moment(payment.created_at).format(
                                "DD-MM-YYYY hh:mm A"
                              )}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                              {isActionLoading === payment.id ? (
                                <p className="text-primary-2 font-bold text-right text-lg">
                                  રાહ જુઓ...
                                </p>
                              ) : (
                                <>
                                  {payment?.status === 0 && (
                                    <button
                                      onClick={() =>
                                        handlePrintReport(payment.id)
                                      }
                                      className="text-indigo-600 hover:text-indigo-900"
                                    >
                                      PDF
                                    </button>
                                  )}
                                  <button
                                    onClick={() => handleEditPayment(payment)}
                                    className="text-indigo-600 hover:text-indigo-900 ml-4"
                                  >
                                    Edit
                                  </button>
                                  <button
                                    onClick={() => handleDeleteRow(payment.id)}
                                    className="text-red-600 hover:text-red-900 ml-4"
                                  >
                                    Delete
                                  </button>
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td
                          colSpan={7}
                          className="text-primary-2 font-bold text-xl text-center py-2"
                        >
                          પેમેન્ટ ની વિગતો મળી નથી
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PaymentList;
