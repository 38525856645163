import {
  useFieldArray,
  UseFormRegister,
  Control,
  Controller,
  FieldErrors,
  UseFormSetValue,
  UseFormClearErrors,
} from "react-hook-form";
import { ItemFormValues, OptionsProps } from "../../types/parties";

import styles from "./ProcessForm.module.scss";
import { GiCancel } from "react-icons/gi";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { getProcesses } from "../../features/parties/partiesItemSlice";
import Select from "react-select";
import { selectStyles } from "../../static/styles";

interface ProcessArrayProps {
  nestIndex: number;
  control: Control<ItemFormValues>;
  register: UseFormRegister<ItemFormValues>;
  errors: FieldErrors<ItemFormValues>;
  setValue: UseFormSetValue<ItemFormValues>;
  clearErrors: UseFormClearErrors<ItemFormValues>;
  isLoading: boolean;
}

function ProcessForm({
  nestIndex,
  control,
  register,
  errors,
  setValue,
  clearErrors,
  isLoading,
}: ProcessArrayProps) {
  const processes = useAppSelector(getProcesses);

  const {
    fields: processFields,
    append: appendProcess,
    remove: removeProcess,
  } = useFieldArray({
    control,
    name: `party_items.${nestIndex}.processes`,
  });

  const [processOption, setProcessOption] = useState<OptionsProps[]>([]);

  const handleChangeProcess = (processId: number, processIndex: number) => {
    if (!processFields.some((p) => p.process_id === processId)) {
      setValue(
        `party_items.${nestIndex}.processes.${processIndex}.process_id`,
        processId
      );
      clearErrors(
        `party_items.${nestIndex}.processes.${processIndex}.process_id`
      );
    }
  };

  useEffect(() => {
    const options =
      processes?.map((process) => ({
        value: process.id,
        label: process.name,
      })) ?? [];
    setProcessOption(options);
  }, [processes]);

  return (
    <div className={styles.ProcessForm}>
      {processFields.length <= 0 && (
        <h2 className="text-primary-2 font-bold text-xl text-center py-2">
          કોઈ પ્રોસેસ મળી નથી, નવી ઉમેરવા માટે પ્રોસેસ ઉંમેરો બટન પર ક્લિક કરો
        </h2>
      )}

      {processFields.map((process, processIndex) => (
        <div
          key={process.id}
          className="mb-4 border-2 border-primary-2 rounded-md p-4 bg-gray-100"
        >
          <h2 className="relative w-full text-2xl lg:text-xl underline text-primary-1 font-bold mb-4">
            પ્રોસેસ - {processIndex + 1}
            <GiCancel
              onClick={() => removeProcess(processIndex)}
              className="absolute cursor-pointer z-10 shadow bg-white top-1 right-1 text-3xl lg:text-xl text-primary-1 border-2 border-primary-1 p-1 lg:p-0 rounded-full"
            />
          </h2>

          <div className="grid lg:flex gap-3">
            <div className="w-full lg:w-1/2">
              <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-12 w-full relative rounded">
                <Controller
                  {...register(
                    `party_items.${nestIndex}.processes.${processIndex}.process_id`,
                    {
                      required: true,
                      min: 1,
                    }
                  )}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      value={
                        processOption.find(
                          (val) => val.value === field.value
                        ) || null
                      }
                      className="text-primary-1 p-0 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                      styles={selectStyles}
                      placeholder={"પ્રોસેસ નું નામ દાખલ કરો"}
                      options={processOption}
                      onChange={(val: any) =>
                        handleChangeProcess(val?.value, processIndex)
                      }
                    />
                  )}
                />
              </div>

              {errors?.party_items?.[nestIndex]?.processes?.[processIndex]
                ?.process_id && (
                <span className="text-red-500">
                  પ્રોસેસ નું નામ સિલેક્ટ કરો
                </span>
              )}
            </div>

            <div className="w-full lg:w-1/2">
              <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-12 w-full relative rounded">
                <input
                  className="text-primary-1 px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                  type="number"
                  pattern="-?[0-9]*"
                  step={"any"}
                  placeholder="પ્રોસેસ નો ભાવ દાખલ કરો"
                  {...register(
                    `party_items.${nestIndex}.processes.${processIndex}.rate`,
                    {
                      required: true,
                      min: 0,
                    }
                  )}
                  onFocus={(e) =>
                    e.target.addEventListener(
                      "wheel",
                      function (e) {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                />
              </div>
              {errors?.party_items?.[nestIndex]?.processes?.[processIndex]
                ?.rate && (
                <span className="text-red-500">પ્રોસેસ નો ભાવ દાખલ કરો</span>
              )}
            </div>
          </div>
        </div>
      ))}

      <div className="text-center">
        <button
          className="mx-auto shadow disabled:opacity-50 rounded-3xl text-3xl lg:text-xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
          type="button"
          onClick={() => appendProcess({ process_id: 0, rate: null })}
          disabled={isLoading}
        >
          પ્રોસેસ ઉંમેરો
        </button>
      </div>
    </div>
  );
}

export default ProcessForm;
