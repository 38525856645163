import React, { useEffect, useState } from "react";

import styles from "./EditForm.module.scss";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { GiCancel } from "react-icons/gi";
import {
  getEditPayment,
  setEditPayment,
  toggleCreateModal,
} from "../../features/payments/paymentsSlice";
import { SubmitHandler, useForm } from "react-hook-form";
import { IFormInput, OptionsProps } from "../../types/payments";
import { FaCalendarAlt } from "react-icons/fa";
import { useParams } from "react-router-dom";
import moment from "moment";

type Props = {
  handleEditPayment: (data: IFormInput) => void;
};

const EditForm = ({ handleEditPayment }: Props) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const editPayment = useAppSelector(getEditPayment);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [orderAmount, setOrderAmount] = useState<number>(0);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [statusOption] = useState<OptionsProps[]>([
    {
      value: 0,
      label: "આવેલ નંગ ના આધારે",
    },
    {
      value: 1,
      label: "પરત નંગ ના આધારે",
    },
  ]);

  const {
    handleSubmit,
    register,
    reset,
    control,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<IFormInput>();

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    if (id) {
      setIsLoading(true);
      await handleEditPayment({
        ...data,
        type: 0,
        id: editPayment?.id,
      });
      setIsLoading(false);
      handleCloseModal();
    }
  };

  const handleCloseModal = () => {
    reset();
    dispatch(toggleCreateModal(0));
    dispatch(setEditPayment(null));
  };

  useEffect(() => {
    if (editPayment && editPayment.id) {
      const total =
        parseFloat(`${editPayment.amount || 0}`) -
        parseFloat(`${editPayment.tax_amount || 0}`) -
        parseFloat(`${editPayment.roundoff_amount || 0}`);
      setOrderAmount(total);
      setTotalAmount(editPayment.amount || 0);

      reset({
        fromDate: editPayment.from_date,
        toDate: editPayment.to_date,
        tax_amount: editPayment.tax_amount,
        roundoff_amount: editPayment.roundoff_amount,
      });
    }
  }, [editPayment]);

  return (
    <div className={styles.EditForm}>
      <div className="relative h-[100vh] w-[100vw] overflow-y-scroll lg:overflow-auto top-0 lg:top-10 lg:mx-auto p-5 border lg:w-[80vw] lg:h-fit lg:min-h-[calc(100vh-80px)] shadow-lg lg:rounded-md bg-white">
        <div className="mt-3">
          <h3 className="text-2xl mt-10 lg:mt-0 text-center lg:text-left font-bold leading-6 text-primary-1">
            હિસાબ ની માહિતી
          </h3>

          <GiCancel
            onClick={handleCloseModal}
            className="absolute cursor-pointer z-10 shadow bg-white top-3 right-5 text-3xl lg:text-xl text-primary-1 border-2 border-primary-1 p-1 lg:p-0 rounded-full"
          />
          <div className="mt-2 py-3 lg:py-2">
            <form onSubmit={handleSubmit(onSubmit)} className="p-4 pt-1 pb-0">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
                <div>
                  <label className="text-primary-1 font-bold">સ્ટેટ્સ :</label>
                  <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-11 w-full relative rounded">
                    <input
                      defaultValue={
                        statusOption.find(
                          (val) => val.value === editPayment?.amount_status
                        )?.label || ""
                      }
                      className="text-primary-1 bg-gray-300 focus:outline-none px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                      type="text"
                      readOnly
                    />
                  </div>
                </div>

                <div>
                  <label className="text-primary-1 font-bold">તારીખ થી :</label>
                  <div className="flex items-center bg-gradient-to-r from-primary-1 to-primary-2 rounded pr-[2px] h-[44px]">
                    <FaCalendarAlt className="text-white mx-2" />
                    <input
                      className="bg-gray-300 text-primary-1 placeholder:text-primary-2 font-semibold text-xl focus:outline-none p-2 rounded flex-grow h-[40px]"
                      type="text"
                      value={moment(editPayment?.from_date).format(
                        "DD-MM-YYYY"
                      )}
                      placeholder="From Date"
                      readOnly
                    />
                  </div>
                </div>

                <div>
                  <label className="text-primary-1 font-bold">
                    તારીખ સુધી :
                  </label>
                  <div className="flex items-center bg-gradient-to-r from-primary-1 to-primary-2 rounded pr-[2px] h-[44px]">
                    <FaCalendarAlt className="text-white mx-2" />
                    <input
                      className="bg-gray-300 text-primary-1  placeholder:text-primary-2 font-semibold text-xl focus:outline-none p-2 rounded flex-grow h-[40px]"
                      type="text"
                      value={moment(editPayment?.to_date).format("DD-MM-YYYY")}
                      placeholder="To Date"
                      readOnly
                    />
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
                <div className="mt-1">
                  <label className="text-primary-1 font-bold">
                    હિસાબ અમાઉન્ટ :
                  </label>
                  <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-11 w-full relative rounded">
                    <input
                      value={orderAmount || 0}
                      className="text-primary-1 bg-gray-300 focus:outline-none px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                      type="text"
                      readOnly
                    />
                  </div>
                </div>

                <div className="mt-1">
                  <label className="text-primary-1 font-bold">
                    ટેક્સ અમાઉન્ટ :
                  </label>
                  <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-11 w-full relative rounded">
                    <input
                      className="text-primary-1 focus:outline-none px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                      type="number"
                      pattern="-?[0-9]*"
                      step={"any"}
                      placeholder="ટેક્સ અમાઉન્ટ દાખલ કરો"
                      {...register("tax_amount", { required: false })}
                      onFocus={(e) =>
                        e.target.addEventListener(
                          "wheel",
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                      onKeyUp={(e: any) => {
                        const { roundoff_amount } = getValues();
                        const tax_amount = e.target.value || 0;

                        const total =
                          parseFloat(`${orderAmount || 0}`) +
                          parseFloat(`${tax_amount || 0}`) +
                          parseFloat(`${roundoff_amount || 0}`);

                        setTotalAmount(total);

                        return true;
                      }}
                    />
                  </div>
                  {errors.tax_amount &&
                    errors.tax_amount.type === "required" && (
                      <span className="text-red-500">
                        ટેક્સ અમાઉન્ટ દાખલ કરો
                      </span>
                    )}
                </div>

                <div className="mt-1">
                  <label className="text-primary-1 font-bold">
                    રાઉન્ડ ઑફ :
                  </label>
                  <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-11 w-full relative rounded">
                    <input
                      className="text-primary-1 focus:outline-none px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                      type="number"
                      pattern="-?[0-9]*"
                      step={"any"}
                      placeholder="રાઉન્ડ ઑફ દાખલ કરો"
                      {...register("roundoff_amount", { required: false })}
                      onFocus={(e) =>
                        e.target.addEventListener(
                          "wheel",
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                      onKeyUp={(e: any) => {
                        const { tax_amount } = getValues();
                        const roundoff_amount = e.target.value || 0;

                        const total =
                          parseFloat(`${orderAmount || 0}`) +
                          parseFloat(`${tax_amount || 0}`) +
                          parseFloat(`${roundoff_amount || 0}`);

                        setTotalAmount(total);

                        return true;
                      }}
                    />
                  </div>
                  {errors.tax_amount &&
                    errors.tax_amount.type === "required" && (
                      <span className="text-red-500">રાઉન્ડ ઑફ દાખલ કરો</span>
                    )}
                </div>
              </div>

              <h3 className="text-2xl mt-6 text-left font-bold leading-8 text-primary-1">
                ટોટલ અમાઉન્ટ - Rs.{" "}
                {totalAmount ? totalAmount?.toFixed(2) : "0.00"}
              </h3>

              <div className="mt-4 text-center">
                <button
                  className="mx-auto shadow disabled:opacity-50 rounded-3xl text-3xl lg:text-xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? "રાહ જુઓ..." : "આગળ વધો"}
                </button>
              </div>

              <div className="mt-4 text-center">
                <button
                  className="mx-auto shadow disabled:opacity-50 rounded-3xl text-3xl lg:text-xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
                  type="button"
                  onClick={handleCloseModal}
                  disabled={isLoading}
                >
                  બંધ કરો
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditForm;
