import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET } from "../../services/axios.service";
import { RootState } from "../../app/store";
import { PaymentsProps } from "../../types/payments";
import { PartiesProps } from "../../types/parties";

export interface PaymentsState {
  party: PartiesProps;
  payments: PaymentsProps[];
  totalPayments: number;
  totalInvoice: number;
  totalPaymnet: number;
  totalNoteAmount: number;
  totalAmount: number;
  isCreateModalOpen: number;
  editPayment: PaymentsProps | null;
  loading: boolean;
  error: string | null;
}

const initialState: PaymentsState = {
  party: {} as PartiesProps,
  payments: [],
  totalPayments: 0,
  totalInvoice: 0,
  totalPaymnet: 0,
  totalNoteAmount: 0,
  totalAmount: 0,
  isCreateModalOpen: 0,
  editPayment: null,
  loading: false,
  error: null,
};

type FetchPaymentsListDataProps = {
  id: string;
  queryParams: {
    page: number;
    size?: number;
    fromDate?: string | null;
    toDate?: string | null;
  };
};

export const fetchPaymentsListData = createAsyncThunk(
  "fetchPaymentsListData",
  async (req: FetchPaymentsListDataProps) => {
    let response: any;
    await GET(`/v1/admin/payments/${req.id}`, { ...req.queryParams })
      .then((res: any) => {
        response = res;
      })
      .catch((err) => {
        response = err;
      });
    return response;
  }
);

export const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    toggleCreateModal: (state, action: PayloadAction<number>) => {
      state.isCreateModalOpen = action.payload;
    },
    setEditPayment: (state, action: PayloadAction<PaymentsProps | null>) => {
      state.editPayment = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPaymentsListData.pending, (state, action: any) => {
      state.payments = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchPaymentsListData.fulfilled, (state, action: any) => {
      state.loading = false;
      if (action.payload.data.status === "success") {
        state.party = action.payload.data.data.party;
        state.payments = action.payload.data.data.records;
        state.totalPayments = action.payload.data.data.total_records;
        state.totalInvoice = action.payload.data.data.total_invoice;
        state.totalPaymnet = action.payload.data.data.total_paymnet;
        state.totalNoteAmount = action.payload.data.data.total_note_amount;
        state.totalAmount = action.payload.data.data.total_amount;
        state.error = null;
      } else {
        state.party = {} as PartiesProps;
        state.payments = [];
        state.totalInvoice = 0;
        state.totalPaymnet = 0;
        state.totalNoteAmount = 0;
        state.totalAmount = 0;
        state.error =
          action?.payload?.data?.message ||
          "કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.";
      }
    });
    builder.addCase(fetchPaymentsListData.rejected, (state, action: any) => {
      state.payments = [];
      state.loading = false;
      state.error =
        action?.payload?.data?.message ||
        "કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.";
    });
  },
});

export const { toggleCreateModal, setEditPayment } = paymentsSlice.actions;

export const getParty = (state: RootState) => state.payments.party;

export const getPayments = (state: RootState) => state.payments.payments;

export const getTotalPayments = (state: RootState) =>
  state.payments.totalPayments;

export const getTotalInvoice = (state: RootState) =>
  state.payments.totalInvoice;

export const getTotalPaymnet = (state: RootState) =>
  state.payments.totalPaymnet;

export const getTotalNoteAmount = (state: RootState) =>
  state.payments.totalNoteAmount;

export const getTotalAmount = (state: RootState) => state.payments.totalAmount;

export const getIsCreateModalOpen = (state: RootState) =>
  state.payments.isCreateModalOpen;

export const getEditPayment = (state: RootState) => state.payments.editPayment;

export default paymentsSlice.reducer;
